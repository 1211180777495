const homePath = '/home';
const rootPath = '/';
const loginPath = '/user/login';
const forgotPasswordPath = '/user/forgot-password';
const resetPasswordPath = '/user/reset-password/:requestId';
const registrationPath = '/user/register';
const adminApplicationsPath = '/admin/applications';
const adminApplicationsRequestDetailPath = '/admin/applications/request/detail/:applicationId';
const adminApplicationsCompletedDetailPath = '/admin/applications/completed/detail/:applicationId';
const adminUserOfficeGroupsPath = '/admin/userOfficeGroups';
const adminUserOfficeGroupDetailPath = '/admin/userOfficeGroups/detail/:userId';
const adminOfficesPath = '/admin/offices';
const adminOfficeDetailPath = '/admin/offices/detail/:officeId';
const applicationsPath = '/applications';
const applicationsDetailPath = '/applications/detail/:applicationId';
const profilePath = '/profile';
const adminPaymentsPath = '/admin/payments';

const adminAdministrativeFeesPath = '/admin/administrativeFees';

const adminSettingsPath = '/settings';
const statementsPath = '/statements';
// customer
const mapWizardPath = '/new';

export {
  homePath,
  rootPath,
  loginPath,
  registrationPath,
  forgotPasswordPath,
  resetPasswordPath,
  adminApplicationsPath,
  adminApplicationsRequestDetailPath,
  adminApplicationsCompletedDetailPath,
  adminUserOfficeGroupsPath,
  adminUserOfficeGroupDetailPath,
  adminOfficesPath,
  adminOfficeDetailPath,
  adminSettingsPath,
  applicationsPath,
  applicationsDetailPath,
  profilePath,
  adminPaymentsPath,
  adminAdministrativeFeesPath,
  statementsPath,
  mapWizardPath
};
