import { configureStore } from '@reduxjs/toolkit';

// import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { settingsReducer, createPredefinedTextsReducer } from './settings/reducer';
import displayReducer from './display/reducer';
import authUser from './auth/reducer';
import optionsReducer from './globals/options';
import sagas from './sagas';
import { createLogger } from 'redux-logger';
import createUserManagementReducer from './userManagement/reducer';
import createProfileReducer from './profile/reducer';
import createApplicationsRequestsReducer from './applications/requestsReducer';
import createApplicationsCompletedReducer from './applications/completedReducer';
import createAdminApplicationsReducer from './applications/reducer';
import createUserOfficeGroupsReducer from './userOfficeGroups/reducer';
import createOfficesReducer from './offices/reducer';
import createUsersReducer from './users/reducer';
import createUserApplicationsReducer from './user/reducer';
import createPaymentsReducer from './payments/reducer';
import cartSlice from './cart/reducer';
import createAdministrativeFeesReducer from './administrativeFees/reducer';

const ENABLE_LOGGER = false;

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const enableLogger =
    process.env.ENV !== 'production' && process.env.NODE_ENV !== 'production' && ENABLE_LOGGER;
  if (enableLogger) {
    const logger = createLogger({
      timestamp: false,
      collapsed: true,
      duration: true
      // diff: true // major performance issues
    });
    middlewares.push(logger);
  }

  const store = configureStore({
    reducer: {
      settings: settingsReducer,
      display: displayReducer,
      authUser: authUser,
      applications: createAdminApplicationsReducer(),
      applicationsRequests: createApplicationsRequestsReducer(),
      applicationsCompleted: createApplicationsCompletedReducer(),
      userApplications: createUserApplicationsReducer(),
      options: optionsReducer,
      userOfficeGroups: createUserOfficeGroupsReducer(),
      offices: createOfficesReducer(),
      userManagement: createUserManagementReducer(),
      users: createUsersReducer(),
      profile: createProfileReducer(),
      predefinedTexts: createPredefinedTextsReducer(),
      payments: createPaymentsReducer(),
      administrativeFees: createAdministrativeFeesReducer(),
      cart: cartSlice
    },
    // middleware: compose(applyMiddleware(...middlewares))
    middleware: middlewares
  });
  sagaMiddleware.run(sagas);
  return store;
};

export default makeStore;
