import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import sagaHelpers from './helpers/sagaHelpers';
import userOfficeGroupsSaga from './userOfficeGroups/saga';
import usersSaga from './users/saga';
import userOfficesSaga from './offices/saga';
import userManagementSaga from './userManagement/saga';
import profileSaga from './profile/saga';
import applicationsRequestsSaga from './applications/requestsSaga';
import applicationsCompletedSaga from './applications/completedSaga';
import userApplicationsSaga from './user/saga';
import predefinedTextsSaga from './settings/saga';
import administrativeFeesSaga from './administrativeFees/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    sagaHelpers(),
    userOfficeGroupsSaga(),
    usersSaga(),
    userOfficesSaga(),
    userManagementSaga(),
    profileSaga(),
    applicationsRequestsSaga(),
    applicationsCompletedSaga(),
    userApplicationsSaga(),
    predefinedTextsSaga(),
    administrativeFeesSaga()
  ]);
}
