import moment from 'moment';
import { isNumber, isFinite } from 'lodash';

const localDate = date => {
  return moment(date).format('DD.MM.YYYY');
};

const localDateTime = date => {
  return moment(date).utc().format('DD.MM.YYYY HH:mm');
};

const queryDate = date => {
  return moment(date).format('YYYY-MM-DD');
};

const currency = amount => {
  if (amount === null || amount === undefined) {
    return '';
  }
  const s = amount.toFixed(2).replace('.', ',');
  return `${s} €`;
};

const isNumeric = value => {
  if (typeof value === 'string') {
    try {
      parseFloat(value);
      return true;
    } catch (Error) {
      return false;
    }
  }

  return isNumber(value) && isFinite(value);
};

const capitalizeFirstLetter = source => {
  if (source && source.length > 0) {
    return `${source.substr(0, 1).toUpperCase()}${source.substr(1)}`;
  }
  return source;
};

export { localDate, localDateTime, currency, isNumeric, queryDate, capitalizeFirstLetter };
