import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchRequestsTable() {
  yield takeLatest('applicationsRequests/fetchTable', fetchTable);
}

function* watchFetchRequestDetail() {
  yield takeLatest('applicationsRequests/fetchDetail', fetchDetail);
}

function* watchSubmitRequestDetail() {
  yield takeEvery('applicationsRequests/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRequestsTable),
    fork(watchFetchRequestDetail),
    fork(watchSubmitRequestDetail)
  ]);
}
