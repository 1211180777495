import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchPredefinedTextsTable() {
  yield takeLatest('predefinedTexts/fetchTable', fetchTable);
}

function* watchFetchPredefinedTextsDetail() {
  yield takeLatest('predefinedTexts/fetchDetail', fetchDetail);
}

function* watchSubmitPredefinedTextsDetail() {
  yield takeLatest('predefinedTexts/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPredefinedTextsTable),
    fork(watchFetchPredefinedTextsDetail),
    fork(watchSubmitPredefinedTextsDetail)
  ]);
}
