import _ from 'lodash';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  adminRoles,
  userRoles,
  officesRoles,
  userOfficeGroupsRoles,
  applicationManagementRoles,
  applicationSolverRoles,
  paymentRoles,
  administrativeFeesRoles,
  invoiceManagementRoles
} from '../constants/defaultValues';
import { pathOr } from 'rambda';
import {
  ApplicationStatus,
  CustomerApplicationStatus,
  InvoiceAllowedOptions,
  PaymentFilterOptions,
  PaymentStatus
} from '../globalEnums';
import Cookies from 'js-cookie';
import { AUTH_TOKEN } from '../api/constants';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};
export const setDirection = localValue => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = color => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentRadius -> error', error);
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = radius => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentRadius -> error', error);
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error);
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = locale => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error);
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('veolia_current_user') != null
        ? JSON.parse(localStorage.getItem('veolia_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = user => {
  try {
    const roles = user?.is_superuser
      ? adminRoles
      : pathOr([], 'user_office_groups', user).map(officeGroup => officeGroup.auth_group.id);

    if (user) {
      localStorage.setItem('veolia_current_user', JSON.stringify({ ...user, roles }));
    } else {
      localStorage.removeItem('veolia_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getActiveUser = () => {
  const token = Cookies.get(AUTH_TOKEN);
  const currentUser = getCurrentUser();
  return token && currentUser?.id ? currentUser : null;
};

export const setDefaultOffice = user => {
  try {
    const groups = _.get(user, 'data.response.user_office_groups');
    // console.log('groups', groups);
    let currentOffice = localStorage.getItem('currentOffice');
    if (currentOffice && !groups.find(group => group.office.id === currentOffice.id)) {
      console.log('current office not allowed, clearing');
      localStorage.removeItem('currentOffice');
      currentOffice = null;
    }
    if (!currentOffice) {
      let primaryOfficeGroup = groups.find(group => group.office.is_primary);
      if (primaryOfficeGroup) {
        const { id, kind } = primaryOfficeGroup.office;
        console.log('no current office found, setting', id, kind);
        localStorage.setItem('currentOffice', JSON.stringify({ id, kind }));
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentOffice -> error', error);
  }
};

export const getCurrentOffice = () => {
  try {
    const officeStr = localStorage.getItem('currentOffice');
    if (officeStr) {
      return JSON.parse(officeStr);
    }
    return null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentOffice -> error', error);
  }
};

export const hasAdminRole = roles => adminRoles.find(role => roles?.includes(role)) !== undefined;

export const hasUserRole = roles => userRoles.find(role => roles?.includes(role)) !== undefined;

export const hasUserOfficeGroupsRole = roles =>
  userOfficeGroupsRoles.find(role => roles?.includes(role)) !== undefined;

export const hasOfficeRole = roles =>
  officesRoles.find(role => roles?.includes(role)) !== undefined;

export const hasApplicationManagementRole = roles =>
  applicationManagementRoles.find(role => roles?.includes(role)) !== undefined;

export const hasApplicationSolverRole = roles =>
  applicationSolverRoles.find(role => roles?.includes(role)) !== undefined;

export const hasPaymentsRole = roles =>
  paymentRoles.find(role => roles?.includes(role)) !== undefined;

export const hasAdministrativeFeesRole = roles =>
  administrativeFeesRoles.find(role => roles?.includes(role)) !== undefined;

export const hasInvoiceManagementRole = roles =>
  invoiceManagementRoles.find(role => roles?.includes(role)) !== undefined;

export const getApplicationStatusOptions = (messages, roles) => {
  const allOptions = Object.values(ApplicationStatus).map(status => ({
    value: status,
    label: messages[`enums.applicationStatus.${status}`]
  }));

  if (roles?.length > 0 && hasApplicationManagementRole(roles)) {
    return allOptions;
  } else if (roles?.length > 0 && hasApplicationSolverRole(roles)) {
    return allOptions.filter(
      status =>
        status.value !== ApplicationStatus.FINISHED && status.value !== ApplicationStatus.CANCELLED
    );
  }

  return allOptions;
};

export const getPaymentStatusOptions = messages =>
  Object.values(PaymentStatus).map(status => ({
    value: status,
    label: messages[`enums.paymentStatus.${status}`]
  }));

export const getInvoiceAllowedOptions = messages =>
  Object.values(InvoiceAllowedOptions).map(status => ({
    value: status,
    label: messages[`enums.invoiceAllowed.${status}`]
  }));

export const getFilterPaymentStatusOptions = messages =>
  Object.values(PaymentFilterOptions).map(status => ({
    label: messages[`enums.paymentStatus.${Array.isArray(status) ? status[0] : status}`],
    value: status
  }));

export const getCustomerApplicationStatus = (status, priceListName, amount) => {
  // if (status === ApplicationStatus.FINISHED && priceListName?.toUpperCase() === 'S1') {
  //   return CustomerApplicationStatus.WITHOUT_NETWORKS;
  // } else if (
  //   status === ApplicationStatus.FINISHED &&
  //   (priceListName?.toUpperCase() === 'S2' ||
  //     priceListName?.toUpperCase() === 'S3' ||
  //     priceListName?.toUpperCase() === 'S4' ||
  //     priceListName?.toUpperCase() === 'S5')
  // ) {
  //   return CustomerApplicationStatus.WITH_NETWORKS;
  // } else if (
  //   status === ApplicationStatus.FINISHED &&
  //   priceListName?.toUpperCase() === 'S6' &&
  //   amount === 0
  // ) {
  //   return CustomerApplicationStatus.WITHOUT_NETWORKS;
  // } else if (
  //   status === ApplicationStatus.FINISHED &&
  //   priceListName?.toUpperCase() === 'S6' &&
  //   amount > 0
  // ) {
  //   return CustomerApplicationStatus.WITH_NETWORKS;
  if (status === ApplicationStatus.FINISHED) {
    return CustomerApplicationStatus.FINISHED;
  } else if (
    status === ApplicationStatus.NEW ||
    status === ApplicationStatus.IN_PROGRESS ||
    status === ApplicationStatus.APPROVE
  ) {
    return CustomerApplicationStatus.PROCESSING;
  } else if (status === ApplicationStatus.CANCELLED) {
    return CustomerApplicationStatus.CANCELLED;
  } else if (status === ApplicationStatus.CANCELLED_NOT_PAYED) {
    return CustomerApplicationStatus.CANCELLED_NOT_PAYED;
  }
  return CustomerApplicationStatus.UNKNOWN;
};

export const isPostalCodeValid = postalCode => postalCode?.length === 5 || postalCode?.length === 6;

export const calculateRenewable = expire_date => {
  if (!expire_date) return false;

  let expireDate = new Date(expire_date);
  let currentDate = new Date();
  // Calculate 2 months before the expiration date
  let twoMonthsBefore = new Date(
    expireDate.getFullYear(),
    expireDate.getMonth() - 2,
    expireDate.getDate()
  );
  // Calculate 3 days before the expiration date
  let threeDaysBefore = new Date(
    expireDate.getFullYear(),
    expireDate.getMonth(),
    expireDate.getDate() - 2
  );
  return currentDate > twoMonthsBefore && currentDate < threeDaysBefore;
};

export const phoneRegExp = /^([ \\+][0-9]{3}\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{3})$/;
