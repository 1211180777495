import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  injectTabSlice,
  toReducer
} from '../sliceFactory';

const createUserOfficeGroupsReducer = () => {
  const sliceBase = createEmptySliceBase({ name: 'userOfficeGroups' });
  const withTabs = injectTabSlice({ initialTab: 0, sliceBase });
  const withTable = injectTableSlice({ sliceBase: withTabs });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export default createUserOfficeGroupsReducer;
