import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchCompletedTable() {
  yield takeLatest('applicationsCompleted/fetchTable', fetchTable);
}

function* watchFetchCompletedDetail() {
  yield takeLatest('applicationsCompleted/fetchDetail', fetchDetail);
}

function* watchSubmitCompletedDetail() {
  yield takeEvery('applicationsCompleted/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCompletedTable),
    fork(watchFetchCompletedDetail),
    fork(watchSubmitCompletedDetail)
  ]);
}
