const StatementStatusColors = {
  new: '#000000',
  in_progress: '#D6BE29',
  approve: '#00ff00',
  finished: '#5BA75B',
  cancelled: '#B22228',
  cancelled_not_payed: '#B22228'
};

export const ApplicationStatus = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  APPROVE: 'approve',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
  CANCELLED_NOT_PAYED: 'cancelled_not_payed'
};

export const CustomerApplicationStatus = {
  WITHOUT_NETWORKS: 'without_networks',
  WITH_NETWORKS: 'with_networks',
  FINISHED: 'finished',
  PROCESSING: 'processing',
  CANCELLED: 'cancelled',
  CANCELLED_NOT_PAYED: 'cancelled_not_payed',
  UNKNOWN: 'unknown'
};

const PaymentStatus = {
  NONE: 'none',
  COMPLETED: 'completed'
};

const PaymentFilterOptions = {
  NONE: ['none', 'in_progress', 'denied', 'rejected', 'timeout', 'pending', 'expired', 'cancelled'],
  COMPLETED: 'completed'
};

export const InvoiceAllowedOptions = {
  ALLOWED: 'allowed',
  NOT_ALLOWED: 'not_allowed'
};

// const PaymentStatus = {
//   NONE: 'none',
//   IN_PROGRESS: 'in_progress',
//   COMPLETED: 'completed',
//   DENIED: 'denied',
//   REJECTED: 'rejected',
//   TIMEOUT: 'timeout',
//   PENDING: 'pending',
//   EXPIRED: 'expired',
//   CANCELLED: 'cancelled'
// };

const PaymentKind = {
  CARD: 'card',
  BANK: 'bank',
  INVOICE: 'invoice'
};

const SubjectStatus = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  REFUSED: 'refused'
};

const LegalForm = {
  SRO: 'sro',
  AS: 'as'
  // KS: 'ks',
  // VOS: 'vos',
  // DRU: 'druzstvo',
  // OTHER: 'other'
};

const OfficeKind = {
  CUSTOMER: 'customer',
  CARRIER: 'carrier',
  WAREHOUSE: 'warehouse',
  EVALUATOR: 'evaluator',
  ADMINISTRATION: 'administration',
  CONTAINER: 'container',
  TRANSHIPMENT: 'transhipment',
  STAFF: 'staff',
  COLLECTION_YARD: 'collection_yard'
};

const OfficeKindList = [
  OfficeKind.CUSTOMER,
  OfficeKind.CARRIER,
  OfficeKind.WAREHOUSE,
  OfficeKind.EVALUATOR,
  OfficeKind.ADMINISTRATION,
  OfficeKind.CONTAINER,
  OfficeKind.TRANSHIPMENT,
  OfficeKind.STAFF,
  OfficeKind.COLLECTION_YARD
];

const TRANSPORT_ENDPOINTS = [
  OfficeKind.WAREHOUSE,
  OfficeKind.EVALUATOR,
  OfficeKind.CONTAINER,
  OfficeKind.TRANSHIPMENT,
  OfficeKind.COLLECTION_YARD
];

const TRANSPORT_ORIGINS = [OfficeKind.WAREHOUSE, OfficeKind.CONTAINER, OfficeKind.EVALUATOR];
const TRANSPORT_DESTINATIONS = [
  OfficeKind.WAREHOUSE,
  OfficeKind.CONTAINER,
  OfficeKind.EVALUATOR,
  OfficeKind.COLLECTION_YARD,
  OfficeKind.TRANSHIPMENT
];

const getOfficeKindTranslations = messages => {
  const result = {};
  OfficeKindList.forEach(v => {
    result[v] = messages[`enums.office_kind.${v}`];
  });
  return result;
};

const RequestStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  PROCESSING: 'processing',
  STORED: 'stored',
  WEIGHTED: 'weighted',
  FINISHED: 'finished'
};

const allRequestStates = [
  RequestStatus.WAITING,
  RequestStatus.ACCEPTED,
  RequestStatus.REFUSED,
  RequestStatus.PROCESSING,
  RequestStatus.STORED,
  RequestStatus.WEIGHTED,
  RequestStatus.FINISHED
];

const OrderStatus = {
  WAITING: 'waiting',
  MANUAL: 'manual',
  ACCEPTED_ENVIDOM: 'accepted_envidom',
  ACCEPTED_CARRIER: 'accepted_carrier',
  PROCESSING: 'processing',
  STORED: 'stored',
  WEIGHTED: 'weighted',
  CHANGED_PRICE: 'changed_price',
  UNINVOICED: 'uninvoiced',
  FINISHED: 'finished',
  FINISHED_ERROR: 'finished_error',
  REFUSED: 'refused'
};

const OrderStatesList = [
  OrderStatus.WAITING,
  OrderStatus.MANUAL,
  OrderStatus.ACCEPTED_ENVIDOM,
  OrderStatus.ACCEPTED_CARRIER,
  OrderStatus.PROCESSING,
  OrderStatus.STORED,
  OrderStatus.WEIGHTED,
  OrderStatus.CHANGED_PRICE,
  OrderStatus.UNINVOICED,
  OrderStatus.FINISHED,
  OrderStatus.FINISHED_ERROR,
  OrderStatus.REFUSED
];

const COLOR_OK = '#28914c';
const COLOR_WAITING = '#d6be08';
const COLOR_FAILURE = '#dd4747';

const orderStateToColor = {
  waiting: COLOR_WAITING,
  manual: COLOR_WAITING,
  accepted_envidom: COLOR_OK,
  accepted_carrier: COLOR_OK,
  processing: COLOR_OK,
  stored: COLOR_OK,
  weighted: COLOR_OK,
  changed_price: COLOR_WAITING,
  uninvoiced: COLOR_WAITING,
  finished: COLOR_OK,
  finished_error: COLOR_FAILURE,
  refused: COLOR_FAILURE
};

const RequestKind = {
  EXPRESS: 'express',
  WITHOUT_DATE: 'without_date',
  WITH_DATE: 'with_date',
  COLLECTION: 'collection',
  PRIVATE: 'private'
};

const RequestKinds = [
  RequestKind.EXPRESS,
  RequestKind.WITHOUT_DATE,
  RequestKind.WITH_DATE,
  RequestKind.COLLECTION,
  RequestKind.PRIVATE
];

const PricingStatus = {
  REFUSED: 'refused',
  WAITING: 'waiting',
  CHANGED_PRICE: 'changed_price',
  UNINVOICED: 'uninvoiced',
  FINISHED: 'finished'
};

const PricingStates = [
  PricingStatus.REFUSED,
  PricingStatus.WAITING,
  PricingStatus.CHANGED_PRICE,
  PricingStatus.UNINVOICED,
  PricingStatus.FINISHED
];

const ItemGroupKind = {
  LIMIT: 'limit',
  HOUSEHOLD: 'household',
  NON_HOUSEHOLD: 'non_household'
};

const InvoiceKind = {
  CUSTOMER: 'customer',
  CARRIER: 'carrier',
  WAREHOUSE: 'warehouse',
  EVALUATOR: 'evaluator',
  ADMINISTRATION: 'administration'
};

const ItemGroupKinds = [ItemGroupKind.LIMIT, ItemGroupKind.HOUSEHOLD, ItemGroupKind.NON_HOUSEHOLD];
const InvoiceKinds = [
  InvoiceKind.CUSTOMER,
  InvoiceKind.CARRIER,
  InvoiceKind.WAREHOUSE,
  InvoiceKind.EVALUATOR,
  InvoiceKind.ADMINISTRATION
];

const InvoiceStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REFUSED: 'refused'
};

const LimitKind = {
  GOVERNMENTAL: 'governmental',
  ENVIDOM: 'envidom',
  PROCESSOR: 'processor'
};
const LimitKinds = [LimitKind.GOVERNMENTAL, LimitKind.ENVIDOM, LimitKind.PROCESSOR];

const InvoiceStates = [InvoiceStatus.WAITING, InvoiceStatus.ACCEPTED, InvoiceStatus.REFUSED];

export {
  StatementStatusColors,
  PaymentStatus,
  PaymentFilterOptions,
  PaymentKind,
  SubjectStatus,
  LegalForm,
  OfficeKind,
  OfficeKindList,
  getOfficeKindTranslations,
  RequestStatus,
  OrderStatus,
  OrderStatesList,
  RequestKind,
  RequestKinds,
  orderStateToColor,
  PricingStatus,
  PricingStates,
  allRequestStates,
  TRANSPORT_ENDPOINTS,
  TRANSPORT_DESTINATIONS,
  TRANSPORT_ORIGINS,
  ItemGroupKind,
  ItemGroupKinds,
  InvoiceKind,
  InvoiceKinds,
  InvoiceStatus,
  InvoiceStates,
  COLOR_OK,
  COLOR_WAITING,
  COLOR_FAILURE,
  LimitKind,
  LimitKinds
};
