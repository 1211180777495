import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../lowLevelUtils';
import invariant from 'invariant';

// toto su ciselniky ziskane cez useGlobalTable
const initialState = {
  offices: null,
  officesState: LoadingState.IDLE,
  groups: null,
  groupsState: LoadingState.IDLE,
  userOffices: null,
  userOfficesState: LoadingState.IDLE,
  requestTypes: null,
  requestTypesState: LoadingState.IDLE
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setTableLoading: (state, action) => {
      invariant(action.payload.tableName, "Must specify table name for 'options' slice");
      state[`${action.payload.tableName}State`] = action.payload.state;
    },
    setTableData: (state, action) => {
      state[`${action.payload.tableName}State`] =
        action.payload.data !== null ? LoadingState.IDLE : LoadingState.ERROR;
      state[action.payload.tableName] = action.payload.data;
    },
    clearGlobals: state => {
      state.offices = null;
      state.officesState = LoadingState.IDLE;
      state.groups = null;
      state.groupsState = LoadingState.IDLE;
      state.userOffices = null;
      state.userOfficesState = LoadingState.IDLE;
      state.requestTypes = null;
      state.requestTypesState = LoadingState.IDLE;
    }
  }
});

export default optionsSlice.reducer;
