import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchUserApplicationsTable() {
  yield takeLatest('userApplications/fetchTable', fetchTable);
}

function* watchFetchUserApplicationsDetail() {
  yield takeLatest('userApplications/fetchDetail', fetchDetail);
}

function* watchSubmitUserApplicationsDetail() {
  yield takeLatest('userApplications/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchUserApplicationsTable),
    fork(watchFetchUserApplicationsDetail),
    fork(watchSubmitUserApplicationsDetail)
  ]);
}
