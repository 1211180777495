import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: { cartItems: [], administrativeFees: [] },
  reducers: {
    addToCart: (state, action) => {
      state.cartItems.push(action.payload);
    },
    updateCartItemRequestId: (state, action) => {
      state.cartItems[state.cartItems.length - 1].request_type_id =
        action.payload.values.request_type_id.value;
      state.cartItems[state.cartItems.length - 1].savedDocuments =
        action.payload.values.savedDocuments;
      state.cartItems[state.cartItems.length - 1].user_comment = action.payload.values.user_comment;
      state.cartItems[state.cartItems.length - 1].object_name = action.payload.values.object_name;
    },
    updateCartPricings: (state, action) => {
      action.payload.products.map(request => {
        const cartItem = state.cartItems.find(item => item.identifier === request.identifier);
        if (cartItem) {
          cartItem.pricing = request.price_lists;
        }
      });
      state.administrativeFees = action.payload.administrative_fees;
    },
    deleteCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(item => item.identifier !== action.payload);
    },
    clearCart: state => {
      state.cartItems = [];
    }
  }
});

export default cartSlice.reducer;
