import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';

const createAdminApplicationsReducer = () => {
  const sliceBase = createEmptySliceBase({ name: 'applicationsCompleted' });
  const withTable = injectTableSlice({ sliceBase });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export default createAdminApplicationsReducer;
