const updateParams = (params, query) => {
  if (query) {
    Object.keys(query).forEach(key => {
      const value = query[key];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach(v => params.append(key, v));
        } else {
          params.append(key, value);
        }
      }
    });
  }
};

const buildTableQueryParams = (page, paginate, limit, sort, filter, query) => {
  const params = new URLSearchParams();
  if (page !== null && page !== undefined) {
    params.append('page', page);
  }
  if (paginate !== null && paginate !== undefined) {
    params.append('paginate', paginate);
  }
  if (limit) {
    params.append('limit', limit);
  }
  if (sort) {
    params.append('order_by', sort);
  }

  updateParams(params, filter);
  updateParams(params, query);

  return params;
};

const queryToParams = query => {
  const params = new URLSearchParams();
  updateParams(params, query);
  return params;
};

export { buildTableQueryParams, queryToParams };
