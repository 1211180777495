import { takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, sendData } from '../helpers/sagaHelpers';

function* watchFetchProfileDetail() {
  yield takeLatest('profile/fetchDetail', fetchDetail);
}

function* watchSubmitProfileDetail() {
  yield takeLatest('profile/submitDetail', sendData);
}

function* watchChangePassword() {
  yield takeEvery('profile/changePassword', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchChangePassword),
    fork(watchFetchProfileDetail),
    fork(watchSubmitProfileDetail)
  ]);
}
