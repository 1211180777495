module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',
  'general.save-changes': 'Uložiť zmeny',
  'general.save-application': 'Uložiť vyjadrenie',
  'general.change_password': 'Zmeniť heslo',
  'general.change_password_not_equals': 'Nové heslo sa nezhoduje',
  'general.application': 'Vyjadrenie',
  'general.download': 'Stiahnuť',
  'general.addUser': 'Pridať používateľa',
  'general.application_renewal': 'Predĺžiť platnosť',

  'modal.removeUser.title': 'Zmazať používateľa',
  'modal.removeUser.message': 'Naozaj si želáte zmazať používateľa',
  'modal.removeUser.button': 'Zmazať',

  'modal.removeApplication.title': 'Zrušiť žiadosť',
  'modal.removeApplication.message': 'Naozaj si želáte zrušiť žiadosť?',
  'modal.removeApplication.button': 'Zrušiť',

  'unauthorized.title': 'Neautorizovaný prístup',
  'unauthorized.detail': 'Nemáte prístup na stránku, ktorú sa pokúšate zobraziť.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Prihlásenie',
  'user.register': 'Registrácia',
  'user.forgot-password': 'Zabudnuté heslo',
  'user.reset-password': 'resetovať heslo',
  'user.login-reset.prompt': 'Ak sa neviete prihlásiť môžete si',
  'user.login-header': 'Prihlásenie do osobnej zóny',
  'user.email': 'E-mail',
  'user.password': 'Heslo',
  'user.forgot-password-question': 'Zabudli ste heslo?',
  'user.fullname': 'Plné meno',
  'user.login-button': 'Prihlásiť sa',
  'user.register-button': 'Registrovať',
  'user.reset-password-button': 'Resetovať',
  'user.buy': 'KÚPIŤ',
  'user.username': 'Username',
  'user.login-error': 'Chyba prihlasovania',
  'user.login-registration.prompt': 'Ak ešte nemáte konto v našom systéme',
  'user.login-registration.link': 'môžete sa zaregistrovať ako objednávateľ.',
  'user.forgot-password.success': 'Skontrolujte e-mailovú schránku a riaďte sa inštrukciami',
  'user.forgot-password.failure': 'Nepodarilo sa poslať žiadosť, skúste prosím znovu',
  'user.new-password': 'Nové heslo',
  'user.new-password-again': 'Znovu zadajte nové heslo',
  'user.reset-password.invalid-token': 'Chýbajúci alebo neplatný kód pre obnovu hesla',
  'user.reset-password.mismatch': 'Zadané heslá sa nezhodujú',
  'user.reset-password.success': 'Heslo úspešne zmenené. Prihláste sa',
  'user.reset-password.expired.message':
    'Vráťte sa prosím na obrazovku prihlásenia, a žiadajte o zmenu zabudnutého hesla znovu',
  'user.reset-password.expired.title': 'Žiadosť o zmenu hesla neexistuje, alebo vypršala.',
  'user.reset-password.success.button': 'Prihlásiť sa',
  'user.reset-password.success.title': 'Heslo bolo zmenené',
  'user.reset-password.success.message': 'Pokračujte prihlásením sa do systému',

  /* 03.Menu */
  'menu.home': 'Domov',
  'menu.app': 'Domov',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',

  /* 04.Error Page */
  'pages.error-title': 'Vyskytla sa chyba',
  'pages.error-code': 'Chybový kód',
  'pages.go-back-home': 'Naspäť na domovskú stránku',
  'pages.login': 'Naspäť na prihlásenie',

  /* ORDER ITEMS (GLOBAL) */
  'items.full.fridges': 'Chladničky',
  'items.full.fridges_incomplete': 'Chladničky nekompletné',
  'items.full.wash_machines': 'Práčky',
  'items.full.small_appliances': 'Ostatné malé spotrebiče',
  'items.full.big_appliances': 'Ostatné veľké spotrebiče',
  'items.full.vacuum_cleaners': 'Vysávače',
  'items.full.mds_incomplete': 'MDS nekompletné',
  'items.full.small_ittz': 'Malé ITTZ',

  'items.short.fridges': 'Chlad.',
  'items.short.fridges_incomplete': 'Ch. nek.',
  'items.short.wash_machines': 'Práčky',
  'items.short.small_appliances': 'OMS',
  'items.short.big_appliances': 'OVS',
  'items.short.vacuum_cleaners': 'Vys.',
  'items.short.mds_incomplete': 'MDS nek.',
  'items.short.small_ittz': 'Malé ITTZ',

  /* REQUESTS */
  'requests.tabs.current.title': 'Aktuálne požiadavky',
  'requests.tabs.current.hint': 'Ešte nespracované požiadavky',
  'requests.tabs.finished.title': 'Dokončené požiadavky',
  'requests.tabs.finished.hint': 'Požiadavky, ktoré už boli dokončené',
  'requests.tabs.button.create_request': 'Vytvoriť požiadavku',
  'requests.create.title': 'Vytvorenie požiadavky',
  'requests.create.button': 'Potvrdiť požiadavku',
  'requests.create.partner': 'Žiadateľ',
  'requests.create.date': 'Dátum požiadavky',
  'requests.create.type': 'Typ zvozu',
  'requests.create.origin': 'Odkiaľ',
  'requests.create.destination': 'Kam',
  'requests.create.type.default': 'Objednať zvoz',
  'requests.create.type.manual': 'Vlastný presun',

  'requests.filter.scheduled_at.from.placeholder': 'Preprava od',

  /* PENDING REQUESTS */
  'requests.pending.title': 'Zoznam aktuálnych požiadaviek',
  'requests.pending.filter.query': 'Zadajte hľadaný výraz',
  'requests.pending.filter.status': 'Stav',
  'requests.pending.filter.carrier': 'Prepravca',
  'requests.pending.filter.customer': 'Zákazník',
  'requests.pending.filter.created_at.from': 'Od',
  'requests.pending.filter.created_at.to': 'Do',

  /* FINISHED REQUESTS */
  'requests.finished.title': 'Zoznam dokončených požiadaviek',

  'requests.pending.table.status': 'Stav',
  'requests.pending.table.estimate': 'Odhad ceny',
  'requests.pending.table.ordered': 'Objednané',
  'requests.pending.table.carrier': 'Prepravca',
  'requests.pending.table.customer': 'Zákazník',
  'requests.pending.table.scheduled_at': 'Dátum prepravy',
  'requests.pending.table.created_at': 'Dátum vytvorenia',

  'requests.pending.table.status.no_order': 'Žiadna objednávka',

  'requests.invoice_popup.title': 'Nahrajte faktúru {order}',
  'requests.invoice_popup.items_title': 'Položky pre fakturáciu za {order}',
  'requests.invoice_popup.items_title_no_order': 'Položky pre fakturáciu',
  'requests.invoice_popup.total': 'Celkovo',
  'requests.invoice_popup.action.confirm': 'Potvrdiť žiadosť',
  'requests.invoice_popup.action.reject': 'Zamietnuť žiadosť',
  'requests.invoice_popup.action.submit': 'Odoslať',
  'requests.invoice_popup.select_item': 'Vyberte položku cenníka',

  'requests.invoice_popup.action_result.rejected': 'Požiadavka zamietnutá',
  'requests.invoice_popup.action_result.reject_failed': 'Zamietnutie požiadavky zlyhalo',

  'requests.invoice_popup.confirm_reject.title': 'Zamietnutie požiadavky',
  'requests.invoice_popup.confirm_reject.subtitle': 'Uveďte nepovinný dôvod zamietnutia požiadavky',
  'requests.invoice_popup.confirm_reject.button': 'Zamietnutie požiadavky',
  'requests.invoice_popup.confirm_reject.message': 'Naozaj zamietnuť požiadavku?',

  'requests.invoice_popup.details.created_at': 'Dátum vytvorenia',
  'requests.invoice_popup.details.created_by': 'Zadal',
  'requests.invoice_popup.details.confirmed_by': 'Potvrdil',
  'requests.invoice_popup.details.plate_number': 'Eč vozidla',
  'requests.invoice_popup.details.number_of_km': 'Počet km',
  'requests.invoice_popup.details.description': 'Popis',
  'requests.invoice_popup.details.distance': 'Vzdialenosť',
  'requests.invoice_popup.details.items_count': 'Počet položiek',
  'requests.invoice_popup.details.weight': 'Hmotnosť',

  'requests.table.ordered_items': 'Objednané položky',

  /* FILTERS (GENERAL) */
  'filters.query.placeholder': 'Zadajte hľadaný výraz',
  'filters.status.placeholder': 'Stav',
  'filters.carrier.placeholder': 'Prepravca',
  'filters.warehouse.placeholder': 'Sklad',
  'filters.customer.placeholder': 'Zákazník',
  'filters.location.placeholder': 'Lokalita',
  'filters.from.placeholder': 'Od',
  'filters.to.placeholder': 'Do',
  'filters.can_renew.placeholder': 'Na predĺženie',
  /* TRANSPORTS */
  'transport.tabs.waiting.title': 'Plánovanie prepravy',
  'transport.tabs.waiting.hint': 'Aktuálne objednávky a plán zvozu',
  'transport.tabs.planned.title': 'Naplánované',
  'transport.tabs.planned.hint': 'Aktuálne prepravované objednávky',
  'transport.tabs.finished.title': 'Dokončené doručenia',
  'transport.tabs.finished.hint': 'Odvezené a zhodnotené objednávky',

  'transport.create_order.popup.title': 'Vytvorenie objednávky',
  'transport.create_order.popup.button': 'Potvrdiť prepravu',
  'transport.create_order.popup.label': 'Poznámka k preprave',

  /* PENDING TRANSPORTS */
  'transport.waiting.open.title': 'Zoznam otvorených požiadaviek',
  'transport.waiting.open.selected_count':
    '{count, plural, one {# vybraná} few {# vybrané} other {# vybraných}}',
  'transport.waiting.open.filter.kind': 'Typ zberu',
  'transport.waiting.open.filter.item_type': 'Typ položky',
  'transport.waiting.open.filter.location': 'Lokalita',
  'transport.waiting.open.filter.office_id': 'Odosielateľ',
  'transport.waiting.open.table.sender': 'Odosielateľ',
  'transport.waiting.open.table.location': 'Mesto a adresa',
  'transport.waiting.open.table.note': 'Poznámka',
  'transport.waiting.open.table.created_at': 'Vytvorená',
  'transport.waiting.open.item.pieces': 'Celkový počet kusov',
  'transport.waiting.open.item.est_weight': 'Odhadovaná váha',
  'transport.waiting.open.item.collection_sites':
    '{count, plural, one {# odberné miesto} few {# odberné miesta} other {# odberných miest}}',
  'transport.destination.transport_note_popup.title': 'Vytvorenie objednávky',
  'transport.destination.transport_note_popup.label': 'Poznámka k preprave',
  'transport.destination.transport_note_popup.submit': 'Potvrdiť prepravu',
  'transport.destination.filter1.title': 'Miesto doručenia a deň zvozu',
  'transport.destination.filter1.switch': 'Zobraziť rozšírené možnosti doručenia',
  'transport.destination.filter1.count': 'Celkový počet kusov',
  'transport.destination.filter1.add_line': 'Pridať ďaľší sklad',
  'transport.destination.filter1.distance': 'Vzdialenosť',
  'transport.destination.filter1.weight': 'Váha',
  'transport.destination.filter1.destination': 'Miesto doručenia',
  'transport.destination.filter1.date': 'Deň zvozu',
  'transport.waiting.plan.title': 'Odporúčaní dopravcovia',
  'transport.waiting.plan.existing': 'Na {date} je naplánovaný zvoz pre {location}',
  'transport.waiting.plan.add_to_existing': 'Zvážte doplánovanie do existujúcej objednávky.',
  'transport.waiting.plan.create': 'Vytvoriť objednávku',
  'transport.waiting.plan.table.name': 'Názov',
  'transport.waiting.plan.filter.region': 'Región',
  'transport.waiting.order_result.title': 'Vytvorenie objednávky - výsledok',
  'transport.waiting.order_result.message.success': 'Objednávka vytvorená',
  'transport.waiting.order_result.message.failure': 'Objednávku sa nepodarilo vytvoriť',
  'transport.waiting.order_result.button': 'OK',
  'transport.detail_popup.title': '{order} {status}',
  'transport.detail_popup.remove_popup.title': 'Zrušiť z prepravy',
  'transport.detail_popup.remove_popup.message': 'Zrušiť z prepravy?',
  'transport.detail_popup.form.submitted_by': 'Odovzdal',
  'transport.detail_popup.form.submitted_at': 'Odovzdal o',
  'transport.detail_popup.form.note': 'Poznámka',
  'transport.detail_popup.form.save_changes': 'Uložiť zmeny',
  'transport.detail_popup.form.remove_from_transport': 'Zrušiť z prepravy',
  'transport.detail_popup.form.abort_changes': 'Zrušiť zmeny',
  'transport.detail_popup.actions.cancel': 'Zrušiť',
  'transport.detail_popup.actions.edit': 'Editovať',
  'transport.detail_popup.actions.accept_carrier': 'Potvrdiť',
  'transport.detail_popup.actions.vehicle': 'Vozidlo',
  'transport.vehicle_popup.form.vehicle': 'Vozidlo',
  'transport.vehicle_popup.form.license_plate': 'ŠPZ',
  'transport.vehicle_popup.form.initial_km': 'Počiaťočný stav km',
  'transport.vehicle_popup.title': 'Výber vozidla',
  'transport.vehicle_popup.button': 'Vybrať',
  'transport.waiting.no_order_description': 'Žiadny popis',

  'transport.detail_popup.detail_panel.created_at': 'Dátum vytvorenia',
  'transport.detail_popup.detail_panel.license_plate': 'EČ vozidla',
  'transport.detail_popup.detail_panel.distance': 'Vzdialenosť',
  'transport.detail_popup.detail_panel.created_by': 'Zadal',
  'transport.detail_popup.detail_panel.kilometers': 'Počet km',
  'transport.detail_popup.detail_panel.items_count': 'Počet položiek',
  'transport.detail_popup.detail_panel.confirmed_by': 'Potvrdil',
  'transport.detail_popup.detail_panel.description': 'Popis',
  'transport.detail_popup.detail_panel.weight': 'Hmotnosť',
  'transport.detail_popup.detail_panel.plan.evaluation': 'Zhodnotenie',
  'transport.detail_popup.detail_panel.plan.stored': 'Uskladnenie',

  'transport.detail_popup.cancel_popup.title': 'Zrušiť objednávku',
  'transport.detail_popup.cancel_popup.label': 'Zadajte dôvod zrušenia objednávky',
  'transport.detail_popup.cancel_popup.button': 'Zrušiť objednávku',
  'transport.detail_popup.accepted_carrier.message': 'Objednávka prijatá prepravcom',

  'transport.planned.title': 'Zoznam naplánovaných objednávok',
  'transport.planned.table.status': 'Stav',
  'transport.planned.table.carrier': 'Prepravca',
  'transport.planned.table.transported': 'Prepravované',
  'transport.planned.table.orders_distance': 'Požiadavky a vzdialenosť',
  'transport.planned.table.last_stop': 'Posledná zastávka',
  'transport.planned.table.estimated_price': 'Odhad ceny',
  'transport.planned.table.scheduled_at': 'Dátum prepravy',
  'transport.planned.table.created_at': 'Dátum vytvorenia',

  'transport.planned.filter.scheduled_at.from.placeholder': 'Preprava od',
  'transport.planned.filter.status.from.placeholder': 'Stav',

  'transport.finished.title': 'Zoznam dokončených objednávok',
  'transport.finished.table.status': 'Stav',
  'transport.finished.table.carrier': 'Prepravca',
  'transport.finished.table.content': 'Prepravované',
  'transport.finished.table.extent': 'Objednávky a vzdialenosť',
  'transport.finished.table.final_price': 'Finálna cena',
  'transport.finished.table.scheduled_at': 'Dátum prepravy',
  'transport.finished.table.created_at': 'Dátum vytvorenia',
  'transport.finished.filter.export_button': 'Exportovať OBJ bez FA',

  'transport.existing.add': 'Pridať do objednávky',
  'transport.existing.current': 'Aktuálne zvozy na plánovaný deň a sklad',

  'transport.groups_filter_popup.title': 'Skupiny pre objednanie',
  'transport.groups_filter_popup.submit': 'Potvrdiť',

  'storages.tabs.current.title': 'Aktuálne na sklade',
  'storages.tabs.current.hint': 'Ešte nespracované doručenia',
  'storages.tabs.waiting.title': 'Čakajúce na prepravu',
  'storages.tabs.waiting.hint': 'Ešte nespracované doručenia',
  'storages.tabs.finished.title': 'Uzavreté spracovania',
  'storages.tabs.finished.hint': 'Požiadavky, ktoré uz boli zhodnotené alebo odvezené',
  'storages.tabs.reports.title': 'Kvartálne hlásenia',
  'storages.tabs.reports.hint': 'Hlásenie spracovávateľa',
  'storages.tabs.button.create_transport': 'Objednať zvoz',
  'storages.card.title.current': 'Zoznam položiek aktuálne na sklade',
  'storages.card.title.waiting': 'Zoznam položiek čakajúcich na prepravu',
  'storages.card.title.finished': 'Zoznam zhodnotených alebo odvezených položiek',
  'storages.no_warehouse.popup.title': 'Vyberte sklad',
  'storages.no_warehouse.popup.message': 'Táto obrazovka je dostupná len správcom skladu',

  'storages.table.status': 'Stav',
  'storages.table.warehouse': 'Sklad',
  'storages.table.price_estimated': 'Odhad ceny',
  'storages.table.totals': 'Objednané',
  'storages.table.carrier': 'Prepravca',
  'storages.table.created_at': 'Dátum prijatia',

  'storages.create_transfer.form.origin_id': 'Odkiaľ',
  'storages.create_transfer.form.request_type': 'Typ zvozu',
  'storages.create_transfer.form.request_type.request': 'Objednať zvoz',
  'storages.create_transfer.form.request_type.local': 'Vlastný presun',
  'storages.create_transfer.form.destination_id': 'Kam',
  'storages.create_transfer.form.scheduled_at': 'Dátum požiadavky',
  'storages.create_transfer.form.title': 'Vytvorenie požiadavky na prepravu',
  'storages.create_transfer.form.button': 'Potvrdiť požiadavku',

  'storages.pending.title': 'Čakajúce na prepravu',

  /* PARTNERS */
  'partners.tabs.partners.title': 'Subjekty',
  'partners.tabs.partners.hint': 'Manažment entít a cenníkov',
  'partners.tabs.users.title': 'Používatelia',
  'partners.tabs.users.hint': 'Manažment používateľov',
  'partners.tabs.button.create_partner': 'Pridať subjekt',
  'partners.tabs.button.create_user': 'Pridať používateľa',

  'partner_popup.title.edit': 'Editácia',
  'partner_popup.title.create': 'Vytvorenie',
  'partner_popup.menu.basic': 'Základné údaje',
  'partner_popup.menu.sites': 'Prevádzky',
  'partner_popup.menu.transport': 'Preprava',
  'partner_popup.menu.add_site': 'Pridať prevádzku',
  'partner_popup.menu.site_details': 'Detaily prevádzky',
  'partner_popup.menu.site_create': 'Vytvorenie prevádzky',
  'partner_popup.menu.price_list_details': 'Cenník pre {name}',
  'partner_popup.menu.car_price_lists': '{plates} - Cenníky',
  'partner_popup.menu.car_details': 'Detail auta',
  'partner_popup.menu.transport_title': 'Preprava - autá',
  'partner_popup.menu.add_car': 'Pridať auto',
  'partner_popup.menu.add_car_price_list': 'Pridať cenník',
  'partner_popup.menu.car_price_list_detail': 'Detail cenníka auta',
  'partner_popup.menu.car_price_list_create': 'Nový cenník',
  'partner_popup.menu.limit_list_title': 'Limity',
  'partner_popup.menu.add_office_limit': 'Pridať limit',
  'partner_popup.menu.update_office_limit': 'Upraviť limit',
  'partner_popup.partner_detail_form.name': 'Názov',
  'partner_popup.partner_detail_form.service_type': 'Doplnkové služby',
  'partner_popup.partner_detail_form.has_storages': 'Prevádzky a sklady',
  'partner_popup.partner_detail_form.has_transport': 'Preprava',
  'partner_popup.partner_detail_form.has_administration': 'Mobilný zber',
  'partner_popup.partner_detail_form.address': 'Ulica',
  'partner_popup.partner_detail_form.legal_form': 'Právna forma',
  'partner_popup.partner_detail_form.public_collections': 'Robí mobilný zber?',
  'partner_popup.partner_detail_form.regions': 'Regióny',
  'partner_popup.partner_detail_form.regions.region': 'Región',
  'partner_popup.partner_detail_form.district': 'Okres',
  'partner_popup.partner_detail_form.city': 'Mesto',
  'partner_popup.partner_detail_form.zip': 'PSČ',
  'partner_popup.partner_detail_form.business_id': 'IČO',
  'partner_popup.partner_detail_form.tax_id': 'DIČ',
  'partner_popup.partner_detail_form.vat_id': 'IČ DPH',
  'partner_popup.partner_detail_form.contact_person': 'Štatutár/konateľ',
  'partner_popup.partner_detail_form.email': 'Email',
  'partner_popup.partner_detail_form.phone': 'Telefón',
  'partner_popup.partner_detail_form.contract_date': 'Dátum zmluvy',
  'partner_popup.partner_detail_form.contract_document': 'Nahrať zmluvu',
  'partner_popup.partner_detail_form.contract_note': 'Poznámka k zmluve',
  'partner_popup.partner_detail_form.confirm_required': 'Musí potvrdzovať dopravcovi prebratie?',
  'partner_popup.partner_detail_form.stamp': 'Pečiatka',
  'partner_popup.partner_detail_form.has_slno': 'SLNO',
  'partner_popup.partner_detail_form.slno_number': 'Číslo súhlasu SLNO',
  'partner_popup.partner_detail_form.validity_date': 'Dátum platnosti',
  'partner_popup.partner_detail_form.slno_code': 'Číslo súhlasu SLNO',
  'partner_popup.partner_detail_form.slno_expires_at': 'Dátum platnosti',
  'partner_popup.partner_detail_form.slno_district_office': 'Okresný úrad',
  'partner_popup.partner_detail_form.slno_document': 'Dokument',
  'partner_popup.partner_detail_form.slno_processing_numbers': 'Kódy spracovania',
  'partner_popup.partner_detail_form.slno_processing_numbers_hint':
    'Jednotlivé kódy oddeľte čiarkami',
  'partner_popup.partner_detail_form.result_updated': 'Subjekt aktualizovaný',
  'partner_popup.partner_detail_form.group_contract_amendments': 'Dodatky k zmluvám',
  'partner_popup.partner_detail_form.appendix.date': 'Dátum dodatku',
  'partner_popup.partner_detail_form.appendix.document': 'Dokument',
  'partner_popup.partner_detail_form.appendix.note': 'Poznámka',
  'partner_popup.car_price_lists.table.origin_regions': 'Východzie regióny',
  'partner_popup.car_price_lists.table.destination_regions': 'Cieľové regióny',
  'partner_popup.car_price_lists.table.type': 'Typ',
  'partner_popup.car_price_lists.table.items': 'Naceňovanie',
  'partner_popup.car_price_lists.table.is_main': 'Je prioritný',

  'partner_popup.sites_list.table.office': 'Prevádzka',
  'partner_popup.sites_list.table.type': 'Typ',
  'partner_popup.sites_list.table.contact_person': 'Kontaktná osoba',
  'partner_popup.sites_list.table.price_list': 'Typ cenníka',
  'partner_popup.sites_list.price_list_type.none': 'Nemá',
  'partner_popup.sites_list.price_list_type.weight': 'za váhu',
  'partner_popup.sites_list.price_list_type.amount': 'za kusy',

  'partner_popup.site_details.name': 'Názov',
  'partner_popup.site_details.address': 'Adresa',
  'partner_popup.site_details.zip': 'PSČ',
  'partner_popup.site_details.city': 'Mesto',
  'partner_popup.site_details.office_kind': 'Typ prevádzky',
  'partner_popup.site_details.description': 'Popis',
  'partner_popup.site_details.contact_person': 'Meno kontaktnej osoby',
  'partner_popup.site_details.email': 'Email',
  'partner_popup.site_details.phone': 'Telefón',
  'partner_popup.site_details.is_autonomous': 'Autonómna',
  'partner_popup.site_details.is_primary': 'Primárna',
  'partner_popup.site_details.is_exempt_autonomous': 'Špeciálna obsluha',
  'partner_popup.site_details.is_weighting': 'Váženie',
  'partner_popup.site_details.parent_id': 'Prekládka pre',
  'partner_popup.site_details.group_slno': 'Čisla súhlasu',

  'partner_popup.price_list.has_flat_rate': 'Mesačný paušál',
  'partner_popup.price_list.abra_codes': 'ABRA KÓDY ak 0 položiek (účtovne)',
  'partner_popup.price_list.add_abra_code': 'Pridať ďaľší kód',

  'partner_popup.car_list.table.car': 'Auto',
  'partner_popup.car_list.table.capacity': 'Nosnosť',
  'partner_popup.car_list.table.dimensions': 'Rozmery',
  'partner_popup.car_list.table.regions': 'Regióny',
  'partner_popup.car_list.table.dimensions_format': '{length}x{width}x{height}',
  'partner_popup.car_list.delete_popup.message':
    'Naozaj chcete odstrániť vozidlo {description} {plates}?',

  'partner_popup.car_detail.form.description': 'Popis',
  'partner_popup.car_detail.form.plate': 'ŠPZ',
  'partner_popup.car_detail.add_license_plate': 'Pridať ŠPZ',
  'partner_popup.car_detail.form.legal_payload': 'Písaná nosnosť (kg)',
  'partner_popup.car_detail.form.real_payload': 'Reálna nosnosť (kg)',
  'partner_popup.car_detail.form.office': 'Prevádzka',
  'partner_popup.car_detail.form.height': 'Výška (cm)',
  'partner_popup.car_detail.form.width': 'Šírka (cm)',
  'partner_popup.car_detail.form.length': 'Dĺžka (cm)',

  'partner_popup.car_price_list_detail.form.type': 'Typ položiek',
  'partner_popup.car_price_list_detail.form.kind': 'Typ cenníka',
  'partner_popup.car_price_list_detail.form.price_per_km': '€ / km',
  'partner_popup.car_price_list_detail.form.is_priority': 'Je prioritný',
  'partner_popup.car_price_list_detail.form.region.location': 'Lokalita',
  'partner_popup.car_price_list_detail.form.region.is_autonomous': 'Je autonómny?',
  'partner_popup.car_price_list_detail.form.region.days': 'Dní na zvoz',
  'partner_popup.car_price_list_detail.form.region_origins': 'Regióny z',
  'partner_popup.car_price_list_detail.form.regions': 'Regióny',
  'partner_popup.car_price_list_detail.form.search_placeholder': 'Vyhľadať',
  'partner_popup.car_price_list_detail.form.region_destinations': 'Miesto doručenia',
  'partner_popup.car_price_list_detail.form.extra_items_name': 'Extra položky',
  'partner_popup.car_price_list_detail.form.extra_items_name_placeholder': 'Názov',
  'partner_popup.car_price_list_detail.form.extra_items_price': 'Cena za ks',
  'partner_popup.car_price_list_detail.form.extra_items_abra_code': 'Kód v ABRA',
  'partner_popup.car_price_list_detail.form.price_lists': 'Cenníky',

  'partner_popup.limits.create.button': 'Uložiť',
  'partner_popup.limits.update.button': 'Uložiť',

  'user_popup.menu.basic': 'Základné údaje',
  'user_popup.menu.assignments': 'Prevádzky a role',
  'user_popup.menu.add_role': 'Pridať rolu',
  'user_popup.menu.update_roles': 'Upraviť role',
  'user_popup.basic_details.form.name': 'Meno',
  'user_popup.basic_details.form.surname': 'Priezvisko',
  'user_popup.basic_details.form.is_staff': 'Prístup do systému',
  'user_popup.basic_details.form.is_staff.inactive': 'Neaktívny',
  'user_popup.basic_details.form.is_staff.active': 'Aktívny',
  'user_popup.basic_details.form.email': 'Email',
  'user_popup.basic_details.form.phone': 'Telefón',
  'user_popup.basic_details.form.password1': 'Heslo',
  'user_popup.basic_details.form.password2': 'Potvrdenie hesla',
  'user_popup.basic_details.form.street': 'Ulica',
  'user_popup.basic_details.form.city': 'Mesto',
  'user_popup.basic_details.form.postal_code': 'PSČ',

  'user_popup.sites_and_roles.table.office': 'Prevádzka',
  'user_popup.sites_and_roles.table.type': 'Typ',
  'user_popup.sites_and_roles.table.role': 'Rola',
  'user_popup.sites_and_roles.table.role.none': 'Nemá',

  'user_popup.title.creating': 'Nový používateľ',
  'user_popup.title.loading': 'Načítavam',

  'user_popup.add_role.group_id': 'Rola',
  'user_popup.add_role.office_id': 'Prevádzka',

  'user_popup.roles.title': '',

  'subjects.title': 'Zoznam subjektov',
  'subjects.table.status': 'Stav',
  'subjects.table.subject_type': 'Typ subjektu',
  'subjects.table.name': 'Názov',
  'subjects.table.business_ids': 'IČO a DIČ',
  'subjects.table.locations': 'Prevádzky',
  'subjects.table.contact': 'Kontaktná osoba',
  'subjects.table.order_summary': 'Sumár objednávok',
  'subjects.table.invoiced_amount': 'Odfakturovaná suma',

  'subjects.filter.subject_type': 'Typ subjektu',
  'subjects.filter.region': 'Región',

  'subjects.type.is_carrier': 'Prepravca',
  'subjects.type.is_office': 'Prevádzka',
  'subjects.type.is_administration': 'Správca',
  'subjects.type.is_staff': 'Zamestnanec',
  'subjects.type.is_slno': 'SLNO',

  'price_list_item.add_range': 'Pridať ďalší rozsah',
  'price_list_item.errors.per_piece_invalid': 'Zadajte platnú cenu za kus',
  'price_list_item.errors.per_kg_invalid': 'Zadajte platnú cenu za kilogram',
  'price_list_item.extra_price_invalid_pc': 'Zadajte platnú cenu a hraničné množstvo',
  'price_list_item.extra_price_invalid_kg': 'Zadajte platnú cenu a hraničnú hmotnosť',

  'users.filter.subject': 'Subjekt',
  'users.filter.status': 'Stav',
  'users.filter.role': 'Rola',

  'users.title': 'Zoznam používateľov',

  'users.table.status': 'Stav',
  'users.table.contact': 'Kontaktná osoba',
  'users.table.subject': 'Názov subjektu',
  'users.table.last_login': 'Posledné prihlásenie',

  'lists.title': 'Číselníky',
  'lists.tabs.postal_codes.title': 'Zoznam PSČ',
  'lists.tabs.postal_codes.hint': 'Upravovateľný zoznam PSČ',
  'lists.tabs.regions.title': 'Regióny',
  'lists.tabs.regions.hint': 'Zoznam existujúcich regiónov',
  'lists.tabs.button.add_postal_code': 'Pridať PSČ',
  'lists.tabs.documents.title': 'Dokumenty',
  'lists.tabs.documents.hint': 'Nastavenia statických dokumentov',
  'lists.tabs.offices.title': 'Prevádzky',
  'lists.tabs.offices.hint': 'Umožňuje prepínanie medzi prevádzkami',
  'lists.tabs.item_categories.title': 'Kategórie',
  'lists.tabs.item_categories.hint': 'Zoznam kategórií odpadu',
  'lists.tabs.item_groups.title': 'Skupiny',
  'lists.tabs.item_groups.hint': 'Skupiny kategórií odpadu',
  'lists.tabs.global_limits.title': 'Limity',
  'lists.tabs.global_limits.hint': 'Pre Envidom a štát',
  'lists.tabs.button.create_group': 'Vytvoriť skupinu',
  'lists.tabs.button.create_limit': 'Vytvoriť limit',
  'lists.tabs.catalog_numbers.title': 'Katalóg',
  'lists.tabs.catalog_numbers.hint': 'Katalógové čísla',
  'lists.tabs.button.create_catalog_number': 'Pridať číslo',
  'lists.tabs.button.create_item_category': 'Pridať kategóriu odpadu',
  'lists.tabs.button.create_additional_pricing': 'Vytvoriť položku',
  'lists.tabs.additional_pricing.title': 'Cenník',
  'lists.tabs.additional_pricing.hint': 'Dodatočné položky cenníkov',
  'lists.tabs.legal_forms.title': 'Právne formy',
  'lists.tabs.legal_forms.hint': 'Zoznam druhov právnych formiem subjektov',
  'lists.tabs.button.create_legal_form': 'Pridať právnu formu',

  'lists.documents.form.ilno': 'Identifikačný list nebezpečného odpadu',
  'lists.documents.form.hp': 'Havarijný plán',
  'lists.documents.form.upload': 'Nahrať',
  'lists.documents.form.download': 'Stiahnuť',
  'lists.documents.uploaded.message': 'Dokument uložený',

  'item_categories.title': 'Kategórie odpadu',
  'item_categories.delete.message': 'Odstrániť kategóriu odpadu?',
  'item_categories.delete.error': 'Nepodarilo sa odstrániť kategóriu odpadu',
  'lists.item_categories.table.name': 'Názov',
  'lists.item_categories.table.short_name': 'Skratka',
  'lists.item_categories.table.abra_code': 'ABRA kód',
  'lists.item_categories.table.item_code': 'Kód položky',
  'lists.item_categories.table.weight': 'Hmotnosť',
  'lists.item_categories.table.is_hazardous': 'Nebezpečný odpad',
  'lists.item_categories.table.item_group': 'Skupina',
  'lists.item_categories.table.catalog_number': 'Katalógové číslo',
  'lists.item_categories.form.name': 'Názov',
  'lists.item_categories.form.short_name': 'Skratka',
  'lists.item_categories.form.abra_code': 'ABRA kód',
  'lists.item_categories.form.item_code': 'Kód položky',
  'lists.item_categories.form.weight': 'Hmotnosť',
  'lists.item_categories.form.is_hazardous': 'Nebezpečný odpad',
  'lists.item_categories.form.item_group': 'Skupina',
  'lists.item_categories.form.catalog_number': 'Katalógové číslo',
  'lists.item_categories.form.sequence': 'Poradie',
  'lists.item_categories.popup.title': 'Kategória odpadu',
  'lists.item_categories.popup.button': 'Uložiť',

  'lists.item_groups.title': 'Skupiny kategórií odpadu',
  'lists.item_groups.table.name': 'Názov',
  'lists.item_groups.table.item_categories': 'Kategórie odpadu',
  'lists.item_groups.table.kind': 'Typ',
  'lists.item_groups.table.created_at': 'Dátum vytvorenia',
  'lists.item_groups.form.name': 'Názov',
  'lists.item_groups.form.short_name': 'Skratka',
  'lists.item_groups.form.kind': 'Typ',
  'lists.item_groups.form.item_categories': 'Kategórie odpadu',
  'lists.item_groups.form.item_categories.placeholder': 'Vyberte možnosti',
  'lists.item_groups.form.coordination_center_share': 'Zberový podiel KC',
  'lists.item_groups.form.current_estimate': 'Odhad aktuálny rok',
  'lists.item_groups.form.envidom_budget': 'Budget ENVIDOM',
  'lists.item_groups.form.error.no_categories': 'Aspoň jedna kategória musí byť zadaná',
  'lists.item_groups.popup.title': 'Skupina kategórií odpadu',
  'lists.item_groups.popup.button': 'Uložiť',
  'lists.item_groups.delete.message': 'Odstrániť skupinu kategórií?',
  'lists.item_groups.delete.error': 'Nepodarilo sa odstrániť skupinu',

  'lists.global_limits.envidom.title': 'Limity Envidom',
  'lists.global_limits.governmental.title': 'Limity štátne',
  'lists.global_limits.table.item_group': 'Skupina',
  'lists.global_limits.table.limit': 'Limit',
  'lists.global_limits.table.year': 'Rok',
  'lists.global_limits.table.name': 'Názov',
  'lists.global_limits.table.created_at': 'Dátum vytvorenia',

  'lists.additional_pricing.table.name': 'Názov',
  'lists.additional_pricing.table.abra_code': 'ABRA kód',
  'lists.additional_pricing.table.price_recommended': 'Odporúčaná cena',
  'lists.additional_pricing.form.name': 'Názov',
  'lists.additional_pricing.form.abra_code': 'ABRA kód',
  'lists.additional_pricing.form.price_recommended': 'Odporúčaná cena',
  'lists.additional_pricing.form.title': 'Dodatočná položka cenníka',
  'lists.additional_pricing.form.button': 'Uložiť',
  'lists.additional_pricing.delete.error': 'Nepodarilo sa odstrániť položku',
  'lists.additional_pricing.delete.message': 'Naozaj odstrániť položku dodatočného nacenenia?',
  'lists.additional_pricing.title': 'Dodatočné položky cenníkov',

  'legal_forms.title': 'Právne formy',
  'lists.legal_forms.table.name': 'Názov',
  'lists.legal_forms.form.name': 'Názov',
  'lists.legal_forms.delete.error': 'Nepodarilo sa odstrániť právnu formu',
  'lists.legal_forms.delete.message': 'Odstrániť právnu formu?',
  'lists.legal_forms.popup.title': 'Detaily právnej formy',
  'lists.legal_forms.popup.button': 'Uložiť',

  'limits.delete.message': 'Odstrániť vybraný limit?',
  'limits.delete.error': 'Nepodarilo sa odstrániť limit',

  'postal_codes.title': 'Zoznam PSČ',
  'postal_codes.table.code': 'PSČ',
  'postal_codes.form.code': 'PSČ',
  'postal_codes.form.title': 'Úprava PSČ',
  'postal_codes.form.submit': 'Uložiť',
  'postal_codes.delete.confirm': 'Naozaj odstrániť PSČ?',
  'postal_codes.delete.success': 'PSČ odstránené',
  'postal_codes.delete.error': 'PSČ sa nepodarilo odstrániť',

  'regions.title': 'Regióny',
  'regions.table.name': 'Názov',
  'regions.table.subject': 'Subjekt',

  'offices.toggle.title': 'Dostupné prevádzky',
  'offices.toggle.button': 'Prepnúť prevádzku',

  'limits.create_popup.button': 'Uložiť',
  'limits.create_popup.title': 'Vytvoriť limit',
  'limits.create_popup.sub_title.envidom': 'Limit pre Envidom',
  'limits.create_popup.sub_title.governmental': 'Štátny limit',

  'limits.edit_popup.button': 'Uložiť',
  'limits.edit_popup.title': 'Upraviť limit',
  'limits.edit_popup.subtitle.envidom': 'Limit pre Envidom',
  'limits.edit_popup.subtitle.governmental': 'Štátny limit',

  'limits.form.item_group_id': 'Skupina',
  'limits.form.name': 'Názov',
  'limits.form.year': 'Rok',
  'limits.form.limit': 'Limit',
  'limits.form.kind': 'Limit pre',

  'reports.tabs.slno_reports.title': 'Tlačivá',
  'reports.tabs.slno_reports.hint': 'SLNO',
  'reports.tabs.button.create_ovo': 'Vytvoriť hlásenie o vzniku odpadu a nakladaní s ním',
  'reports.tabs.button.create_elo': 'Vytvoriť evidenčný list odpadov',
  'reports.tabs.button.create_processed_report': 'Evidencia spracovaného odpadu',
  'reports.tabs.button.create_management_report': 'Hlásenie manažmentu',

  'slno_reports.title': 'Zoznam SLNO',
  'slno_reports.table.status': 'Stav',
  'slno_reports.table.customer': 'Objednávateľ',
  'slno_reports.table.carrier': 'Prepravca',
  'slno_reports.table.unload_site': 'Miesto vyloženia',
  'slno_reports.table.summary': 'Sumár objednávky',
  'slno_reports.table.created_at': 'Dátum objednávky',
  'slno_reports.table.transported_at': 'Dátum prepravy',
  'slno_reports.table.evaluated_at': 'Dátum zhodnotenia',

  'slno_reports.filter.customer': 'Objednávateľ',
  'slno_reports.filter.carrier': 'Prepravca',
  'slno_reports.filter.unload_site': 'Miesto vyloženia',
  'slno_reports.filter.created_at.from.placeholder': 'Vytv. od',
  'slno_reports.filter.created_at.to.placeholder': 'Do',

  'slno_reports.create_ovo_popup.title': 'Vytvoriť hlásenie o vzniku odpadu a nakladaní s ním',
  'slno_reports.create_ovo_popup.button': 'Vytvoriť',
  'slno_reports.create_ovo_popup.form.year': 'Rok',
  'slno_reports.create_ovo_popup.form.quarter': 'Štvrťrok',
  'slno_reports.create_ovo_popup.form.office_id': 'Odosielateľ',

  'slno_reports.create_elo_popup.title': 'Evidenčný list odpadov',
  'slno_reports.create_elo_popup.button': 'Vytvoriť',
  'slno_reports.create_elo_popup.form.range': 'Rozsah',
  'slno_reports.create_elo_popup.form.office_id': 'Odosielateľ',
  'slno_reports.create_elo_popup.form.item_category_id': 'Kategória',

  'slno_reports.create_processed_popup.title': 'Evidencia spracovaného odpadu',
  'slno_reports.create_processed_popup.button': 'Vytvoriť',
  'slno_reports.create_processed_popup.form.year': 'Rok',

  'slno_reports.create_management_report_popup.title': 'Hlásenie manažmentu',
  'slno_reports.create_management_report_popup.button': 'Vytvoriť',
  'slno_reports.create_management_report_popup.form.year': 'Rok',

  'catalog_numbers.title': 'Katalógové čísla',
  'catalog_numbers.table.name': 'Názov',
  'catalog_numbers.table.code': 'Kód',
  'catalog_numbers.table.created_at': 'Dátum vytvorenia',
  'catalog_numbers.form.title': 'Katalógové číslo',
  'catalog_numbers.form.name': 'Názov',
  'catalog_numbers.form.code': 'Kód',
  'catalog_numbers.form.submit': 'Uložiť',
  'catalog_numbers.delete.message': 'Odstrániť katalógové číslo?',
  'catalog_numbers.delete.error': 'Nepodarilo sa odstrániť katalógové číslo',

  'pricing.request.title': 'Nacenenie {order}',
  'pricing.request.details.created_at': 'Dátum',
  'pricing.request.details.kind': 'Typ',
  'pricing.request.details.customer_name': 'Zákazník',
  'pricing.request.details.address': 'Adresa',
  'pricing.request.details.customer_contact_number': 'Kontakt',
  'pricing.request.details.order_amount': 'Počet',
  'pricing.request.details.order_weight': 'Hmotnosť',
  'pricing.request.details.desc_req': 'Poznámka',
  'pricing.request.details.desc_rec': 'Poznámka.',

  'pricing.order.details.created_at': 'Vytvorená',
  'pricing.order.details.scheduled_at': 'Naplánovná',
  'pricing.order.details.created_by': 'Vytvoril(a)',
  'pricing.order.details.license_plate': 'ŠPZ',
  'pricing.order.details.distance': 'Vzdialenosť',
  'pricing.order.details.duration': 'Trvanie',
  'pricing.order.details.amount': 'Množstvo',
  'pricing.order.details.description': 'Popis',
  'pricing.order.details.weight': 'Hmotnosť',
  'pricing.order.title': 'Nacenenie objednávky',

  'pricing.warehouse_log.title': 'Nacenenie uskladnenia',
  'pricing.warehouse_log.details.created_at': 'Vytvorené',
  'pricing.warehouse_log.details.carrier': 'Prepravca',
  'pricing.warehouse_log.details.destination': 'Cieľ',
  'pricing.warehouse_log.details.order_name': 'Objednávka',
  'pricing.warehouse_log.details.total_amount': 'Množstvo',
  'pricing.warehouse_log.details.total_weight': 'Hmotnosť',

  'pricing_popup.form.price_id': 'Položka',
  'pricing_popup.form.amount': 'Množstvo',
  'pricing_popup.form.price': 'Cena',
  'pricing_popup.form.description': 'Poznámka',

  'pricing_popup.proposed_price': 'Celkovo',
  'pricing_popup.action.confirm': 'Potvrdiť žiadosť',

  'static_pricing.table.name': 'Názov',
  'static_pricing.table.amount': 'Množstvo',
  'static_pricing.table.price': 'Cena',
  'static_pricing.table.created_at': 'Vytvorené',

  'pricing.reject_popup.title': 'Zamietnutie nacenenia',
  'pricing.reject_popup.label': 'Zadajte voliteľný dôvod zamietnutia',
  'pricing.reject_popup.button': 'Zamietnuť',

  'pricing_approval_popup.approve_success': 'Nacenenie prijaté',
  'pricing_approval_popup.approve_failure': 'Zlyhalo schválenie nacenenia',
  'pricing_approval_popup.reject_success': 'Nacenenie zamietnuté',
  'pricing_approval_popup.reject_failure': 'Zlyhalo zamietnutie nacenenia',
  'pricing_approval_popup.actions.approve': 'Schváliť',
  'pricing_approval_popup.actions.reject': 'Zamietnuť',
  'pricing_approval_popup.title.request': 'Schválenie nacenenia požiadavky',
  'pricing_approval_popup.note.requester': 'Poznámka žiadateľa',
  'pricing_approval_popup.title.recipient': 'Poznámka prijímateľa',

  'invoicing_popup.requests.title': 'Vytvorenie faktúry',
  'invoicing_popup.title': 'Nová faktúra',
  'invoicing_popup.footer.total_price': 'Celkovo',
  'invoicing_popup.footer.submit': 'Odoslať',
  'invoicing_popup.filter.created_at.placeholder': 'Vytvorená',
  'invoicing_popup.table.object': 'Predmet',
  'invoicing_popup.table.subject': 'Subjekt',
  'invoicing_popup.table.address': 'Mesto a adresa',
  'invoicing_popup.table.note': 'Poznámka',
  'invoicing_popup.table.created_at': 'Vytvorené',
  'invoicing_popup.item.items_total': 'Suma za objednávku',
  'invoicing_popup.item.total': 'Celkovo',
  'invoicing_popup.file_upload.hint':
    'Potiahnutím súboru alebo kliknutím nahrajte faktúru vo formáte PDF',

  'invoices.title': 'Zoznam faktúr',
  'invoices.tabs.invoices.title': 'Faktúry',
  'invoices.tabs.invoices.hint': 'Zoznam faktúr',
  'invoices.tabs.button.create_invoice': 'Vytvoriť faktúru',

  'invoices.filter.status': 'Stav',
  'invoices.filter.subject': 'Subjekt',
  'invoices.filter.customer': 'Zákazník',
  'invoices.filter.accepted_at.from.placeholder': 'Schválené od',
  'invoices.filter.accepted_at.to.placeholder': 'Do',
  'invoices.filter.export_all': 'Stiahnuť všetky FA',

  'invoices.table.status': 'Stav',
  'invoices.table.subject': 'Subjekt',
  'invoices.table.type': 'Typ',
  'invoices.table.orders': 'Požiadavky a objednávky',
  'invoices.table.total': 'Suma',
  'invoices.table.summary': 'Sumár faktúry',
  'invoices.table.accepted_at': 'Dátum potvrdenia',
  'invoices.table.created_at': 'Dátum vytvorenia',

  'profile.tabs.profile.title': 'Profil',
  'profile.tabs.profile.hint': 'Detaily používateľa',

  'profile.change_password.button': 'Zmeniť heslo',
  'profile.change_password.title': 'Zmena hesla',
  'profile.change_password.old_password': 'Aktuálne heslo',
  'profile.change_password.new_password1': 'Nové heslo',
  'profile.change_password.new_password2': 'Zopakujte nové heslo',
  'profile.change_password.submit': 'Zmeniť',
  'profile.change_password.message.mismatch': 'Opakované nové heslo sa nezhoduje',
  'profile.change_password.message.success': 'Heslo bolo zmenené',
  'profile.change_password.message.failure': 'Heslo sa nepodarilo zmeniť',

  'controls.pieces': 'ks',
  'controls.kg': 'kg',
  'controls.yes': 'Áno',
  'controls.ok': 'OK',
  'controls.cancel': 'Zrušiť',
  'controls.download': 'Stiahnuť',
  'controls.pieces_full': '{count, plural, one {# kus} few {# kusy} other {# kusov}}',
  'controls.requests':
    '{count, plural, one {# požiadavka} few {# požiadavky} other {# požiadaviek}}',
  'controls.eur_per_kg': '€ / KG',
  'controls.eur_per_pc': '€ / KS',
  'controls.price_other_pc': 'ostatné kusy',
  'controls.price_amount_separator': 'do (ks)',
  'controls.no': 'Nie',
  'controls.confirm': 'Potvrdiť',
  'controls.confirm_delete.title': 'Potvrďte odstránenie objektu',
  'controls.dropdown.placeholder': 'Vyberte možnosť',
  'controls.forms.field_required': 'Pole je povinné',
  'forms.controls.field_required': 'Pole je povinné',
  'controls.forms.field_5_6_characters': 'Pole musí mať 5 alebo 6 znakov',
  'controls.forms.number_required': 'Zadajte celé, kladné čislo',
  'controls.forms.any_number_required': 'Zadajte číslo',
  'controls.forms.integer_number_required': 'Zadajte celé čislo',
  'controls.forms.positive_number_required': 'Zadajte kladné čislo',
  'controls.forms.number_missing': 'Aspoň jedna hodnota musí byť zadaná',
  'controls.forms.invalid_email': 'Neplatný e-mail',
  'controls.forms.non_empty_array': 'Zadajte aspoň jednu položku',
  'controls.forms.phone_number_invalid': 'Telefónne číslo je v nesprávnom formáte',
  'controls.dropzone.upload': 'Vyberte súbor',
  'controls.dropzone.uploads': 'Vyberte súbory',
  'controls.capacity_kg': '{capacity} kg',
  'controls.capacity_real_kg': '{capacity} kg (reálna)',
  'controls.formset.add': 'Pridať ďalšiu položku',
  'controls.forms.passwords_mismatch': 'Zadané heslá sa nezhodujú',
  'controls.clear_filter': 'Zmazať filter',
  'controls.no_reason': 'Dôvod neuvedený',
  'controls.forms.avi.missing_amount': 'Množstvo je povinné',
  'controls.forms.avi.invalid_amount': 'Neplatné množstvo',
  'controls.forms.avi.missing_weight': 'Hmotnosť je povinná',
  'controls.forms.avi.invalid_weight': 'Neplatná hmotnosť',
  'controls.forms.avi.items.at_least_one': 'Aspoň jedna položka musí byť zadaná',
  'controls.forms.date_range.start_required': 'Počiatočný dátum je požadovaný',
  'controls.forms.date_range.end_required': 'Koncový dátum je požadovaný',
  'controls.wrong_download_form': 'Nesprávne vyplnené údaje',
  'controls.wrong_download_internal_error': 'Chyba pri generované výstupu',
  'controls.wrong_download_other_error': 'Chyba pri generované výstupu',
  'controls.popup.error.title': 'Došlo k chybe',
  'controls.downloads.label': 'Dokumenty na stiahnutie',
  'controls.uploads.confirm_remove.title': 'Odstrániť prílohu',
  'controls.uploads.confirm_remove.message': 'Naozaj odstrániť prílohu?',
  'controls.uploads.confirm_remove.button': 'Odstrániť',

  'enums.price_item_type.inactive': 'Nerelevantné',
  'enums.price_item_type.weight': 'Váha',
  'enums.price_item_type.amount': 'Kusy',
  'enums.amount_dependency.independent': 'Nezávisí na počte',
  'enums.amount_dependency.dependent': 'Závisí na počte',
  'enums.car_price_list_type.default': 'Štandardný',
  'enums.car_price_list_type.public': 'Verejný',
  'enums.car_price_list_type.private': 'Súkromný',

  'enums.car_price_list_type_dropdown.distance': 'Vzdialenosť',
  'enums.car_price_list_type_dropdown.weight_and_pieces': 'Váha a kusy',

  'enums.request_status.waiting': 'Čakajúca',
  'enums.request_status.accepted': 'Prijatá',
  'enums.request_status.refused': 'Zamietnutá',
  'enums.request_status.processing': 'Nakladanie',
  'enums.request_status.stored': 'V preprave',
  'enums.request_status.finished': 'Dokončená',
  'enums.request_status.weighted': 'Uskladnená',

  'enums.subject_status.waiting': 'Čakajúci na schválenie',
  'enums.subject_status.approved': 'Schválený',
  'enums.subject_status.refused': 'Zamietnutý',

  'enums.office_kind.customer': 'Odosielateľ',
  'enums.office_kind.carrier': 'Prepravca',
  'enums.office_kind.warehouse': 'Sklad',
  'enums.office_kind.evaluator': 'Spracovateľ',
  'enums.office_kind.administration': 'Samospráva',
  'enums.office_kind.container': 'Kontajner',
  'enums.office_kind.transhipment': 'Prekládka',
  'enums.office_kind.staff': 'Zamestnanec',
  'enums.office_kind.collection_yard': 'Zberný dvor',

  'enums.role.staff_management': 'Manažment',
  'enums.role.staff_view': 'Prehľad',
  'enums.role.staff_logistic': 'Logistika',
  'enums.role.staff_processing': 'Spracovanie',
  'enums.role.staff_collection': 'Zber',
  'enums.role.staff_administrator': 'Administrátor',
  'enums.role.subject_administrator': 'Administrátor subjektu',
  'enums.role.subject_accountant': 'Účtovník subjektu',
  'enums.role.subject_logistic': 'Logistika subjektu',

  'enums.legal_form.sro': 's.r.o.',
  'enums.legal_form.as': 'a.s.',
  'enums.legal_form.ks': 'k.s.',
  'enums.legal_form.vos': 'v.o.s.',
  'enums.legal_form.druzstvo': 'družstvo',
  'enums.legal_form.other': 'iná',

  'enums.vehicle.price_list_items.weight': 'Váha',
  'enums.vehicle.price_list_items.amount': 'Kusy',
  'enums.vehicle.price_list_items.distance': 'Vzdialenosť',

  'enums.vehicle.price_list_kind.default': 'Štandardný',
  'enums.vehicle.price_list_kind.public': 'Verejný',
  'enums.vehicle.price_list_kind.private': 'Súkromný',

  'enums.user_status.waiting': 'Čakajúci',
  'enums.user_status.approved': 'Schválený',
  'enums.user_status.refused': 'Zamietnutý',

  'enums.order_status.waiting': 'Čakajúca',
  'enums.order_status.manual': 'Manuálna',
  'enums.order_status.accepted_envidom': 'Prijatá envidom',
  'enums.order_status.accepted_carrier': 'Prijatá prepravcom',
  'enums.order_status.processing': 'Nakladanie',
  'enums.order_status.stored': 'V preprave',
  'enums.order_status.weighted': 'Uskladnená',
  'enums.order_status.changed_price': 'Zmenená cena',
  'enums.order_status.uninvoiced': 'Nefakturovaná',
  'enums.order_status.finished': 'Dokončená',
  'enums.order_status.finished_error': 'Dokončená s chybou',
  'enums.order_status.refused': 'Zamietnutá',

  'enums.request_kind.express': 'Expresný',
  'enums.request_kind.without_date': 'Bez dátumu',
  'enums.request_kind.with_date': 'S dátumom',
  'enums.request_kind.collection': 'Mobilný zber',
  'enums.request_kind.private': 'Súkromný',

  'enums.pricing_status.refused': 'Zamietnuté',
  'enums.pricing_status.waiting': 'Čakajúce',
  'enums.pricing_status.changed_price': 'Zmena ceny',
  'enums.pricing_status.uninvoiced': 'Nefaktúrované',
  'enums.pricing_status.finished': 'Dokončené',

  'enums.item_group_kind.limit': 'Limit',
  'enums.item_group_kind.household': 'Použitie v domácnosti',
  'enums.item_group_kind.non_household': 'Použitie v nedomácnosti',

  'enums.invoice_kind.customer': 'Zákazník',
  'enums.invoice_kind.carrier': 'Prepravca',
  'enums.invoice_kind.warehouse': 'Sklad',
  'enums.invoice_kind.evaluator': 'Spracovateľ',
  'enums.invoice_kind.administration': 'Samospráva',

  'enums.invoice_status.waiting': 'Čaká na schválenie',
  'enums.invoice_status.accepted': 'Schválená',
  'enums.invoice_status.refused': 'Zamietnutá',

  'enums.limit_kind.envidom': 'Envidom',
  'enums.limit_kind.governmental': 'Štát',
  'enums.limit_kind.processor': 'Spracovávateľ',

  'response_errors.err_network': 'Chyba sieťového pripojenia',
  'response_errors.err_fetch_title': 'Nepodarilo sa získať údaje',
  'response_errors.err_delete_title': 'Nepodarilo sa odstrániť objekt',
  'response_errors.err_send_title': 'Nepodarilo sa odoslať údaje',
  'response_errors.bad_request': 'Nesprávne alebo nekompletné údaje',
  'response_errors.err_bad_response': 'Interná chyba servera',
  'response_errors.err_bad_request': 'Nesprávne formulovaná požiadavka',
  'response_errors.econnaborted': 'Odpojené',
  'response.errors.404': 'Objekt sa nenašiel',
  'response.errors.422': 'Nekompletné alebo nesprávne údaje',
  'response.errors.500': 'Interná chyba servera',
  // sample texty
  'sampleSlice.tabs.sample_tab_1.title': 'Vzorova tabulka',
  'sampleSlice.tabs.sample_tab_1.hint': 'Hint k vzorovej tabulke',

  'sampleSlice.tabs.sample_tab_2.title': 'Vzorovy detail',
  'sampleSlice.tabs.sample_tab_2.hint': 'Hint ku vzorovemu detailu tabulke',
  'sampleSlice.tabs.button.sample_action': 'Vzorova akcia na tabe',
  'sampleSlice.table.title': 'Nazov vzorovej tabulky',
  'sampleSlice.table.filter.office.placeholder': 'Pobocka',
  'sampleSlice.table.filter.scheduled_at.from.placeholder': 'Naplanovana medzi',
  'sampleSlice.table.status': 'Stav',
  'sampleSlice.table.person': 'Osoba',
  'sampleSlice.table.amount': 'Mnozstvo',
  'sampleSlice.table.active': 'Aktivna',
  'sampleSlice.detail.form.first_name': 'Meno',
  'sampleSlice.detail.form.last_name': 'Priezvisko',

  'applications.tabs.applicationsRequests.title': 'Žiadosti na vyjadrenie',
  // 'applications.tabs.requests.hint': 'Hint ku Žiadosti na vyjadrenie',
  'applications.tabs.applicationsCompleted.title': 'Ukončené žiadosti',
  // 'applications.tabs.completed.hint': 'Hint ku Ukončené žiadosti',

  'applications.table.filter.type.placeholder': 'Všetky typy vyjadrení',
  'applications.table.filter.payment': 'Všetky platby',
  'applications.table.filter.history': 'Celá história',

  'applications.table.identifier': 'Číslo žiadosti',
  'applications.table.status': 'Stav',
  'applications.table.company': 'Spoločnosť',
  'applications.table.companyLocation': 'Lokalita',
  'applications.table.place': 'Predmetné miesto',
  'applications.table.address': 'Adresa',
  'applications.table.requestAddress': 'Adresa žiadosti',
  'applications.table.applicant': 'Žiadateľ',
  'applications.table.contractor': 'Zhotoviteľ',
  'applications.table.statement': 'Vyjadrenie',
  'applications.table.payment_date': 'Dátum platby',
  'applications.table.payment': 'Platba',
  'applications.table.date': 'Dátum žiadosti',
  'applications.table.renew': 'Predĺžiť',
  'applications.payment.by': 'cez',

  'applications.detail.form.title': 'Vyjadrenie',
  'applications.detail.form.user_comment': 'Poznámka: ',
  'applications.detail.form.object_name': 'Názov stavby: ',
  'applications.detail.form.renewal': 'Predĺženie vyjadrenia',
  'applications.detail.form.recipient': 'Prijímateľ',
  'applications.detail.form.statement': 'Vyjadrenie na',
  'applications.detail.form.name': 'Meno',
  'applications.detail.form.officialName': 'Názov',
  'applications.detail.form.surname': 'Priezvisko',
  'applications.detail.form.address': 'Adresa',
  'applications.detail.form.city': 'Mesto',
  'applications.detail.form.postalCode': 'PSČ',
  'applications.detail.form.phone': 'Telefón',
  'applications.detail.form.email': 'Email',
  'applications.detail.form.business_number': 'IČO',
  'applications.detail.form.tax_number': 'DIČ',
  'applications.detail.form.vat_number': 'IČ DPH',
  'applications.detail.form.requestTypeId': 'Dôvod žiadosti',
  'applications.detail.form.comment': 'Interná poznámka',
  'applications.detail.form.header': 'Hlavička',
  'applications.detail.form.requestState': 'Stav žiadosti',
  'applications.detail.form.paymentState': 'Stav platby',
  'applications.detail.form.paymentKind': 'Spôsob platby',
  'applications.detail.form.amount': 'Suma',
  'applications.detail.form.variableSymbol': 'Variabilný symbol',
  'applications.detail.form.invoiceUpload': 'Nahranie faktúry',
  'applications.detail.form.invoiceAllowed': 'Povolenie faktúry',
  'applications.detail.form.issued': 'Vystavil',
  'applications.detail.form.issuedOn': 'Vystavil dňa',
  'applications.detail.form.saved': 'Dáta boli uložené',
  'applications.detail.form.header.select.placeholder': 'Vyberte text',

  'applications.detail.map.legend': 'Legenda',

  'requests.detail.form.first_name': 'Meno',
  'requests.detail.form.last_name': 'Priezvisko',

  'userOfficeGroups.tabs.userOfficeGroups.title': 'Zoznam používateľov',

  'userOfficeGroups.table.filter.role': 'Všetky typy rolí',
  'userOfficeGroups.table.filter.roleRequired': 'Musí byť vybraná aspoň jedna prevádzka',
  'userOfficeGroups.table.filter.roleNotAllowed': 'Ekonóm nemá mať vybranú žiadnu prevádzku',

  'userOfficeGroups.table.name': 'Meno',
  'userOfficeGroups.table.veolia': 'Veolia',
  'userOfficeGroups.table.role': 'Rola',
  'userOfficeGroups.table.lastLoggedIn': 'Posledné prihlásenie',
  'userOfficeGroups.table.createdAt': 'Dátum vytvorenia',

  'userOfficeGroups.detail.form.role': 'Typ osoby',
  'userOfficeGroups.detail.form.role.manager': 'Manažér',
  'userOfficeGroups.detail.form.role.solver': 'Riešiteľ žiadostí',
  'userOfficeGroups.detail.form.role.veolia': 'Veolia',
  'userOfficeGroups.detail.form.role.business': 'Obchod',
  'userOfficeGroups.detail.form.role.economist': 'Ekonóm',
  'userOfficeGroups.detail.form.name': 'Meno',
  'userOfficeGroups.detail.form.surname': 'Priezvisko',
  'userOfficeGroups.detail.form.phone': 'Telefón',
  'userOfficeGroups.detail.form.email': 'Email',
  'userOfficeGroups.detail.form.position': 'Funkcia',
  'userOfficeGroups.detail.form.stamp': 'Pečiatka',
  'userOfficeGroups.detail.form.allowedOffices': 'Povolené pobočky',
  'userOfficeGroups.detail.form.saved': 'Dáta boli uložené',
  'userOfficeGroups.detail.form.deleted': 'Dáta boli vymazané',
  'userOfficeGroups.detail.form.errorDeleted': 'Chyba pri vymazaní dát',

  'offices.tabs.offices.title': 'Zoznam prevádzok',
  'offices.table.name': 'Meno',
  'offices.table.manager': 'Manažér',
  'offices.table.prices': 'Ceny',
  'offices.table.createdAt': 'Dátum vytvorenia',

  'offices.detail.form.name': 'Názov',
  'offices.detail.form.short_name': 'Skrátený názov',
  'offices.detail.form.city': 'Mesto',
  'offices.detail.form.internal_city': 'Interné mesto',
  'offices.detail.form.responsiblePerson': 'Zodpovedná osoba',
  'offices.detail.form.managerName': 'Meno',
  'offices.detail.form.managerSurname': 'Priezvisko',
  'offices.detail.form.managerContactNumber': 'Telefón',
  'offices.detail.form.managerEmail': 'Email',
  'offices.detail.form.managerPosition': 'Pozícia',
  'offices.detail.form.statement': 'Vyjadrenie',
  'offices.detail.form.business_number': 'IČO',
  'offices.detail.form.tax_number': 'DIČ',
  'offices.detail.form.vat_number': 'IČ DPH',
  'offices.detail.form.subject_address': 'Adresa',
  'offices.detail.form.subject_postal_code': 'PSČ',

  'payments.tabs.payments.title': 'Export platieb',
  'payments.button.export': 'Exportovať platby',

  'payments.export.form.name': 'Exportovať platby',

  'payments.export.file.name': 'export_platieb',
  'payments.export.form.title': 'Exportovať platby',
  'payments.export.form.dateRange': 'Rozsah dátumov',

  'administrativeFees.tabs.administrativeFees.title': 'Administratívne poplatky',
  'administrativeFees.table.name': 'Názov',
  'administrativeFees.table.price': 'Cena',

  'administrativeFees.form.title': 'Upraviť cenu',
  'administrativeFees.form.price': 'Cena',

  'userApplications.tabs.userApplications.title': 'Moje vyjadrenia',

  'profile.form.role': 'Typ osoby',
  'profile.form.role.individual': 'Fyzická',
  'profile.form.role.business': 'Právnická',
  'profile.form.role.government': 'Štátna inštitúcia',
  'profile.form.official_name': 'Názov',
  'profile.form.business_number': 'IČO',
  'profile.form.tax_number': 'DIČ',
  'profile.form.vat_number': 'IČ DPH',
  'profile.form.subject_address': 'Adresa sídla',
  'profile.form.subject_postal_code': 'PSČ sídla',
  'profile.form.subject_city': 'Obec sídla',
  'profile.form.name': 'Meno',
  'profile.form.surname': 'Priezvisko',
  'profile.form.address': 'Adresa',
  'profile.form.city': 'Mesto',
  'profile.form.postalCode': 'PSČ',
  'profile.form.phone': 'Telefón',
  'profile.form.email': 'Email',
  'profile.form.password': 'Pôvodné heslo',
  'profile.form.password_new': 'Nové heslo',
  'profile.form.password_reply': 'Zopakovať heslo',
  'profile.form.saved': 'Dáta boli uložené',
  'profile.form.passwordChanged': 'heslo bolo zmenené',

  'enums.applicationStatus.new': 'Nová',
  'enums.applicationStatus.in_progress': 'Rozpracovaná',
  'enums.applicationStatus.approve': 'Na schválenie',
  'enums.applicationStatus.finished': 'Vyriešená',
  'enums.applicationStatus.cancelled': 'Zrušená',
  'enums.applicationStatus.cancelled_not_payed': 'Zrušená - neuhradené',

  'enums.paymentStatus.none': 'Nezaplatené',
  'enums.paymentStatus.in_progress': 'Čaká sa na platbu',
  'enums.paymentStatus.completed': 'Zaplatené',
  'enums.paymentStatus.denied': 'Odmietnutá platba',
  'enums.paymentStatus.rejected': 'Zamietnutá platba',
  'enums.paymentStatus.timeout': 'Vypršaná platba',
  'enums.paymentStatus.pending': 'Čaká na spracovanie',
  'enums.paymentStatus.expired': 'Vypršaná platba',
  'enums.paymentStatus.cancelled': 'Zrušená platba',

  'enums.customerApplicationStatus.finished': 'Vyriešená',
  'enums.customerApplicationStatus.without_networks': 'Bez sietí',
  'enums.customerApplicationStatus.with_networks': 'So sietami',
  'enums.customerApplicationStatus.processing': 'Spracováva sa',
  'enums.customerApplicationStatus.cancelled': 'Zrušená',
  'enums.customerApplicationStatus.cancelled_not_payed': 'Zrušená - neuhradené',
  'enums.customerApplicationStatus.unknown': 'Neznámy',

  'enums.paymentKind.card': 'Platba kartou',
  'enums.paymentKind.bank': 'Bankový prevod',
  'enums.paymentKind.invoice': 'Faktúra',

  'enums.invoiceAllowed.allowed': 'Povolená',
  'enums.invoiceAllowed.not_allowed': 'Nepovolená',

  'enums.sampleStatus.waiting': 'Cakajuca',
  'enums.sampleStatus.finished': 'Dokoncena',
  'enums.sampleStatus.rejected': 'Zamietnuta',

  'public.customer_app.title': 'Zákaznícka zóna',
  'public.customer_app.new.title': 'Nové podanie',
  'public.menu.cart': 'Žiadosti v košíku',
  'public.menu.closeCart': 'Zavrieť košík',
  'public.menu.pricing': 'Cenník',
  'public.menu.contact': 'Kontakt',
  'public.menu.login': 'Prihlásenie',

  'cart.message.itemAdded': 'Žiadosť bola pridaná do košíka',
  'cart.message.itemUpdated': 'Žiadosť bola aktualizovaná',

  'public.wizard.back': 'Naspäť',
  'public.wizard.counter': '{step} z 5',
  'public.wizard.continue': 'Pokračovať',
  'public.wizard.step1.title': 'Vyberte si ako chcete označiť oblasť',
  'public.wizard.step1.hint':
    'Pre vyhľadané miesto si budete vedieť doplniť presnú lokalitu, pre ktoré sa má vyjadrenie vydať',
  'public.wizard.step1.option1.title': 'Pomocou výberu parcely',
  'public.wizard.step1.option1.hint': 'Ak sa týka vyjadrenie konkrétnej parcely',
  'public.wizard.step1.option2.title': 'Vlastný výber na mape',
  'public.wizard.step1.option2.hint':
    'V prípade, ak chcete presne vybrať územie bez ohľadu na parcelu',
  'public.wizard.step2.title': 'Vyhľadanie adresy',
  'public.wizard.step2.hint':
    'Pre vyhľadané miesto si budete vedieť doplniť presnú lokalitu, pre ktorú sa má vyjadrenie vydať',
  'public.wizard.step2.plot.title': 'Vyhľadanie parcely',
  'public.wizard.step2.plot.hint': 'Zadajte údaje o parcele, ktorú chcete vyhľadať',
  'public.wizard.step2.form.search': 'Vyhľadať',
  'public.wizard.step2.form.search.placeholder': 'Napíšte adresu',
  'public.wizard.step2.form.address': 'Adresa',
  'public.wizard.step2.form.number_registry': 'Súpisné číslo',
  'public.wizard.step2.form.number_street': 'Orientačné číslo',
  'public.wizard.step2.form.zip_code': 'PSČ',
  'public.wizard.step2.form.city': 'Obec',
  'public.wizard.step2.form.plot_area': 'Katastrálne územie',
  'public.wizard.step2.form.plot_type': 'Typ parcely',
  'public.wizard.step2.form.plot_number': 'Číslo parcely',
  'public.wizard.step2.form.plot_type.c': 'Parcela registra C',
  'public.wizard.step2.form.plot_type.e': 'Parcela registra E',
  'public.wizard.step2.continue': 'Pokračovať na označenie',
  'public.wizard.step3.title': 'Označenie miesta',
  'public.wizard.step3.hint':
    'V rámci mapy môžete označiť jedno územie pre ktoré chcete požiadať o vyjadrenie.',
  'public.wizard.step3.option1': 'Pridať vyznačenie',
  'public.wizard.step3.option2': 'Zmazať vyznačenia',
  'public.wizard.step3.option3': 'Upraviť vyznačenia',
  'public.wizard.step3.is_line_construction': 'Pridať Líniovú stavbu',
  'public.wizard.step3.edit.apply': 'Uložiť zmeny',
  'public.wizard.step3.edit.cancel': 'Zrušiť zmeny',
  'public.wizard.step3.error.area_too_big': 'Vybraná oblasť je príliš veľká',

  'public.wizard.requestIdDocumentsStep.title': 'Dôvod žiadosti',
  'public.wizard.requestIdDocumentsStep.continue': 'Pokračovať k platbe',
  'public.wizard.requestIdDocumentsStep.newRequest': 'Pridať ďalšiu žiadosť',

  'public.wizard.step4.title': 'Údaje o žiadateľovi',
  'public.wizard.step4.login': 'Ak už máte konto, možete sa <link>prihlásiť</link>.',
  'public.wizard.step4.form.is_subject': 'Typ žiadateľa',
  'public.wizard.step4.form.subject.individual': 'Fyzická osoba',
  'public.wizard.step4.form.subject.business': 'Právnická osoba',
  'public.wizard.step4.form.subject.government': 'Štátna inštitúcia',
  'public.wizard.step4.form.person.individual': 'Fyzická',
  'public.wizard.step4.form.person.legal': 'Právnická',
  'public.wizard.step4.form.name': 'Meno',
  'public.wizard.step4.form.surname': 'Priezvisko',
  'public.wizard.step4.form.email': 'E-mail',
  'public.wizard.step4.form.contact_number': 'Tel. číslo',
  'public.wizard.step4.form.contact_number.placeholder': '+421 9XX ...',
  'public.wizard.step4.form.requestType': 'Dôvod žiadosti',
  'public.wizard.step4.form.documents': 'Dokumenty',
  'public.wizard.step4.errors.document_required': 'Pre tento typ žiadosti sú Dokumenty povinné',
  'public.wizard.step4.form.user_comment': 'Poznámka',
  'public.wizard.step4.form.object_name': 'Názov stavby',

  'public.wizard.step4.form.address': 'Adresa',
  'public.wizard.step4.form.city': 'Obec',
  'public.wizard.step4.form.postal_code': 'PSČ',

  'public.wizard.step4.form.payByInvoice': ' Úhrada faktúrou',
  'public.wizard.step4.form.official_name': 'Názov',
  'public.wizard.step4.form.business_number': 'IČO',
  'public.wizard.step4.form.tax_number': 'DIČ',
  'public.wizard.step4.form.vat_number': 'IČ DPH',
  'public.wizard.step4.form.subject_address': 'Adresa sídla',
  'public.wizard.step4.form.subject_postal_code': 'PSČ sídla',
  'public.wizard.step4.form.subject_city': 'Obec sídla',

  'public.wizard.step4.continue': 'Pokračovať na sumár',
  'public.wizard.step5.title': 'Sumár a platba',
  'public.wizard.step5.label_object': 'Vydanie bude podané na:',
  'public.wizard.step5.administrative_fee': 'Administratívny poplatok',
  'public.wizard.step5.payment_option': 'Úhrada',
  'public.wizard.step5.payment.invoice': 'Prevodom (na faktúru)',
  'public.wizard.step5.payment.card': 'Platobnou kartou',
  'public.wizard.step5.label_recipient': 'Prijímateľ:',
  'public.wizard.step5.label_summary': 'Produkt:',
  'public.wizard.step5.agreement.check': 'Súhlasím',
  'public.wizard.step5.agreement.link':
    '<u><a href="https://vesr.sk/sites/default/files/Podmienky%20poskytovania%20vyjadrení%20%2B%20GDPR%20info.pdf" target="_blank">s podmienkami poskytovania Vyjadrení k inžinierskym sieťam, ktoré prevádzkujú spoločnosti skupiny VE SK.</a></u>',

  'public.wizard.step5.continue': 'Zaplatiť a odoslať',

  'public.payment.invoice.success.title': 'Vitajte v systéme vyjadrení Veolia',
  'public.payment.invoice.success.message':
    'Vašu žiadosť o vyjadrenie sme zaevidovali. Na mailovú adresu Vám budú zaslané podrobnosti ohľadom platobných údajov.',

  'public.payment.success.message':
    'Ďakujeme za platbu. Keď bude vaše vyjadrenie pripravené, budeme vás informovať prostredníctvom emailu. Vašu žiadosť zároveň uvidíte v osobnej zóne po prihlásení.',
  'public.payment.success.title': 'Vaša platba prebehla úspešne',
  'public.payment.success.button': 'Do osobnej zóny',

  'public.payment.failure.button': 'Zopakovať',
  'public.payment.failure.title': 'Vaša platba bola neúspešná',
  'public.payment.failure.message':
    'Žiadosť o vyjadrenie bola uložená, ale vaša platba nebola úspešná. Platbu môžete skúsiť zopakovať, alebo poukázať peniaze prevodom na účet:',

  'public.payment.failure.table.iban': 'IBAN',
  'public.payment.failure.table.variable_symbol': 'Variabilný symbol',
  'public.payment.failure.table.description': 'Poznámka',
  'public.payment.failure.table.amount': 'Suma',

  'public.wizard.error_fields.city': 'Mesto',
  'public.wizard.error_fields.address': 'Adresa',
  'public.wizard.error_fields.postal_code': 'PSČ',
  'public.wizard.error_fields.descriptive_number': 'Popisné číslo',
  'public.wizard.error_fields.indicative_number': 'Orientačné číslo',
  'public.wizard.error_fields.area': 'Vybraná oblasť',
  'public.wizard.error_fields.user.email': 'E-mail',
  'public.wizard.error_fields.user.name': 'Meno',
  'public.wizard.error_fields.user.surname': 'Priezvisko',
  'public.wizard.error_fields.user.city': 'Mesto',
  'public.wizard.error_fields.user.address': 'Adresa',
  'public.wizard.error_fields.user.postal_code': 'PSČ',
  'public.wizard.error_fields.user.contact_number': 'Telefón',
  'public.wizard.error_fields.user.position': 'Pozícia',

  'public.wizard.error_fields.user.official_name': 'Názov',
  'public.wizard.error_fields.user.business_number': 'IČO',
  'public.wizard.error_fields.user.tax_number': 'DIČ',
  'public.wizard.error_fields.user.vat_number': 'IČ DPH',

  'public.wizard.error_fields.payment.payer_name': 'Meno platcu',
  'public.wizard.error_fields.payment.payer_email': 'E-mail platcu',
  'public.wizard.error_fields.kind': 'Spôsob vymedzenia',
  'public.wizard.error_fields.request_type': 'Typ žiadosti',

  'settings.tabs.predefinedTexts.title': 'Nastavenia',
  'settings.predefined_texts.title': 'Preddefinované texty',
  'settings.predefined_texts.table.title': 'Názov v menu',
  'settings.predefined_texts.table.content': 'Obsah',
  'settings.predefined_texts.table.kind': 'Typ textu',

  'settings.predefined_texts.filter.kind': 'Typ textu',

  'settings.predefined_texts.kind.header': 'Hlavička',
  'settings.predefined_texts.kind.text': 'Obsah',
  'settings.predefined_texts.kind.application': 'Text vyjadrenia',
  'settings.predefined_texts.detail.title.create': 'Vytvoriť text',
  'settings.predefined_texts.detail.title.update': 'Upraviť text',
  'settings.predefined_texts.detail.form.title': 'Názov v menu',
  'settings.predefined_texts.detail.form.content': 'Obsah',
  'settings.predefined_texts.detail.form.kind': 'Typ textu',

  'settings.tabs.button.new_text': 'Nový obsah/hlavička',
  'settings.tabs.button.new_application_text': 'Nový text vyjadrenia'
};
