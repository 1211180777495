import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchAdministrativeFeesTable() {
  yield takeLatest('administrativeFees/fetchTable', fetchTable);
}

function* watchFetchAdministrativeFeesDetail() {
  yield takeLatest('administrativeFees/fetchDetail', fetchDetail);
}

function* watchSubmitAdministrativeFeesDetail() {
  yield takeLatest('administrativeFees/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchAdministrativeFeesTable),
    fork(watchFetchAdministrativeFeesDetail),
    fork(watchSubmitAdministrativeFeesDetail)
  ]);
}
