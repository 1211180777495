export const UserRole = {
  Admin: 0,
  Solver: 1,
  Manager: 2,
  Veolia: 3,
  Business: 4,
  Economist: 5
};

export const adminRoles = [
  UserRole.Admin,
  UserRole.Veolia,
  UserRole.Manager,
  UserRole.Business,
  UserRole.Solver,
  UserRole.Economist
];
export const userOfficeGroupsRoles = [UserRole.Admin, UserRole.Veolia];
export const officesRoles = [UserRole.Admin, UserRole.Veolia];
export const userRoles = [];
export const applicationManagementRoles = [
  UserRole.Admin,
  UserRole.Veolia,
  UserRole.Manager,
  UserRole.Economist,
  UserRole.Business
];
export const applicationSolverRoles = [UserRole.Solver, UserRole.Economist];

export const paymentRoles = [UserRole.Admin, UserRole.Veolia, UserRole.Economist];

export const administrativeFeesRoles = [UserRole.Veolia];

export const invoiceManagementRoles = [
  UserRole.Admin,
  UserRole.Veolia,
  UserRole.Economist,
  UserRole.Business
];

// export const cancelledNotPayedAvailableRoles = [
//   UserRole.Admin,
//   UserRole.Economist,
//   UserRole.Business
// ];
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'sk';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' }
];

export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`;
export const customerRoot = '/customer';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'default.dark';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [];
