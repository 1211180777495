import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { deleteObject, fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchUsersTable() {
  yield takeLatest('users/fetchTable', fetchTable);
}

function* watchFetchUserDetail() {
  yield takeLatest('users/fetchDetail', fetchDetail);
}

function* watchSubmitUserDetail() {
  yield takeEvery('users/submitDetail', sendData);
}

function* watchDeleteUser() {
  yield takeLatest('users/deleteUser', deleteObject);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchUsersTable),
    fork(watchFetchUserDetail),
    fork(watchSubmitUserDetail),
    fork(watchDeleteUser)
  ]);
}
