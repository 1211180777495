import axios from 'axios';
import { all, fork, takeEvery, call, put, cancelled } from 'redux-saga/effects';
import { GetApi, PostApi } from '../../api/api';
import _ from 'lodash';
import { extractErrorMessage, extractFormErrors, extractStatus } from '../../api/utils';
import { LoadingState, rawTranslate } from '../../lowLevelUtils';
import { buildTableQueryParams, queryToParams } from '../../api/urlQuery';
import invariant from 'invariant';

const getDataAsync = async (
  { url, page, paginate, limit, order_by, filter, query },
  cancelToken
) => {
  invariant(url, 'URL not specified');
  return GetApi({
    url,
    params: buildTableQueryParams(page, paginate, limit, order_by, filter, query),
    cancelToken,
    handleResponse: response => response,
    handleError: error => error
  });
};

const getDetailAsync = async ({ url, query }, cancelToken) => {
  invariant(url, 'URL not specified');
  return GetApi({
    url,
    params: queryToParams(query),
    cancelToken,
    handleResponse: response => response,
    handleError: error => error
  });
};

const sendDataAsync = async ({ url, data, method }) =>
  PostApi({
    url,
    data,
    method,
    handleResponse: response => response,
    handleError: error => error
  });

function* fetchGlobalTable({ payload }) {
  const { tableName, sliceName } = payload;
  const cancelSource = axios.CancelToken.source();
  invariant(tableName, 'tableName not specified for global table load');
  yield put({
    type: `${sliceName}/setTableLoading`,
    payload: { tableName, state: LoadingState.LOADING }
  });
  try {
    const response = yield call(
      getDataAsync,
      { ...payload, page: 1, paginate: false },
      cancelSource.token
    );
    const data =
      _.get(response, 'data.items', null) || _.get(response, `data.response.${tableName}`, null);
    const { status } = response;
    if (status !== 200) {
      const payload = {
        message: extractErrorMessage(response),
        type: 'error',
        title: rawTranslate('response_errors.err_fetch_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      yield put({
        type: `${sliceName}/setTableLoading`,
        payload: { tableName, state: LoadingState.ERROR }
      });
    } else {
      yield put({ type: 'options/setTableData', payload: { tableName, data } });
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_fetch_title')
    };
    yield put({ type: 'display/reportMessage', payload });
    yield put({
      type: `${sliceName}/setTableLoading`,
      payload: { tableName: payload.tableName, state: LoadingState.ERROR }
    });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

const dummyFn = () => {};

function* sendData({ payload }) {
  const onFinish = payload.onFinish || dummyFn;
  try {
    const response = yield call(sendDataAsync, payload);
    if (payload.finishAction) {
      yield put({ type: payload.finishAction, payload: {} });
    }
    const status = extractStatus(response);
    if (status === 422) {
      const validationErrors = _.get(response, 'response.data.validation_errors', []);
      const title = _.get(response, 'response.data.title', '');
      const formErrors = extractFormErrors(validationErrors);
      if (title) {
        formErrors.nonField.push(title);
      }
      onFinish(false, formErrors, response.response.data || null);
    } else if (status >= 300) {
      console.log('on finish with other error status', status);
      const message = extractErrorMessage(response);
      const payload = {
        message,
        type: 'error',
        title: rawTranslate('response_errors.err_send_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      const formErrors = { nonField: [message], fields: {}, skipped: [] };
      onFinish(false, formErrors);
    } else {
      onFinish(true, null, response.data || null);
    }
  } catch (error) {
    console.log('sendData error');
    const message = extractErrorMessage(error);
    console.trace(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_fetch_title')
    };
    yield put({ type: 'display/reportMessage', payload });
    if (payload.finishAction) {
      yield put({ type: payload.finishAction, payload: {} });
    }
    onFinish(false);
  }
}

function* deleteObject({ payload }) {
  const { onFinish, url } = payload;
  invariant(onFinish, 'onFinish handler missing');
  invariant(url, 'url not specified');
  const alteredPayload = { ...payload };
  alteredPayload.method = payload.method || 'DELETE';
  try {
    const response = yield call(sendDataAsync, alteredPayload);
    if (payload.finishAction) {
      yield put({ type: payload.finishAction, payload: {} });
    }
    const status = extractStatus(response);
    if (status >= 300) {
      console.log('on finish with other error status', status);
      const message = extractErrorMessage(response);
      const payload = {
        message,
        type: 'error',
        title: rawTranslate('response_errors.err_delete_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      onFinish(false);
    } else {
      onFinish(true);
    }
  } catch (error) {
    console.log('deleteObject error');
    const message = extractErrorMessage(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_delete_title')
    };
    yield put({ type: 'display/reportMessage', payload });
    if (payload.finishAction) {
      yield put({ type: payload.finishAction, payload: {} });
    }
    yield put({ type: 'display/reportMessage', payload });
    onFinish(false);
  }
}

function* fetchTable({ payload }) {
  const { url, page, limit, order_by, filter, query, setTableDataAction, sliceName } = payload;
  invariant(sliceName, 'fetchTable action: sliceName not set');
  const cancelSource = axios.CancelToken.source();
  try {
    const setTableLoadingAction = `${sliceName}/${
      payload.setTableLoadingAction || 'setTableLoading'
    }`;
    yield put({ type: setTableLoadingAction, payload: true });
    const response = yield call(
      getDataAsync,
      { url, page, limit, order_by, filter, query },
      cancelSource.token
    );
    const data = _.get(response, 'data.items', null);
    const meta = _.get(response, 'data.metadata', null);
    const { status } = response;
    if (status !== 200) {
      const payload = {
        message: extractErrorMessage(response),
        type: 'error',
        title: rawTranslate('response_errors.err_fetch_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      yield put({ type: setTableLoadingAction, payload: false });
    } else {
      const actionType = `${sliceName}/${setTableDataAction || 'setTableData'}`;
      yield put({
        type: actionType,
        payload: { tableName: payload.tableName, data, meta }
      });
      yield put({ type: setTableLoadingAction, payload: false });
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_fetch_title')
    };
    yield put({ type: `${payload.sliceName}/setTableLoading`, payload: false });
    yield put({ type: 'display/reportMessage', payload });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* fetchAuxTable({ payload }) {
  const { url, filter, query, sliceName, setDataActionName, limit } = payload;
  invariant(url, 'fetchAuxTable: url not specified in payload');
  invariant(sliceName, 'fetchAuxTable: sliceName not specified in payload');
  invariant(setDataActionName, 'fetchAuxTable: setDataActionName not specified in payload');
  const cancelSource = axios.CancelToken.source();
  try {
    const response = yield call(
      getDataAsync,
      { url, filter, query, limit: limit || 10000 },
      cancelSource.token
    );
    const data = _.get(response, 'data.items', null);
    const { status } = response;
    if (status === 200) {
      const actionType = `${sliceName}/${setDataActionName}`;
      yield put({ type: actionType, payload: { data } });
    } else {
      const payload = {
        message: extractErrorMessage(response),
        type: 'error',
        title: rawTranslate('response_errors.err_fetch_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      yield put({ type: setDataActionName, payload: { data: null } });
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_fetch_title')
    };
    yield put({ type: 'display/reportMessage', payload });
    yield put({ type: `${sliceName}/${setDataActionName}`, payload: { data: null } });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* fetchDetail({ payload }) {
  const { url, sliceName, setLoadingAction, setDetailAction } = payload;
  const cancelSource = axios.CancelToken.source();
  invariant(sliceName, 'fetchDetail: missing sliceName');
  try {
    const response = yield call(getDetailAsync, { url }, cancelSource.token);
    const { status } = response;
    if (status === 200) {
      const data = _.get(response, 'data.response', null);
      yield put({ type: `${sliceName}/${setDetailAction || 'setDetail'}`, payload: { data } });
    } else {
      const payload = {
        message: extractErrorMessage(response),
        type: 'error',
        title: rawTranslate('response_errors.err_fetch_title')
      };
      yield put({ type: 'display/reportMessage', payload });
      yield put({ type: `${sliceName}/${setLoadingAction || 'setDetailLoading'}`, payload: false });

      // reset data to null, just to be sure we dont have any older (cached) data to be shown as current data
      yield put({
        type: `${sliceName}/${setDetailAction || 'setDetail'}`,
        payload: { data: null }
      });
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    const payload = {
      message,
      type: 'error',
      title: rawTranslate('response_errors.err_fetch_title')
    };
    yield put({ type: 'display/reportMessage', payload });
    yield put({
      type: `${payload.sliceName}/${setLoadingAction || 'setDetailLoading'}`,
      payload: false
    });

    // reset data to null, just to be sure we dont have any older (cached) data to be shown as current data
    yield put({ type: `${sliceName}/${setDetailAction || 'setDetail'}`, payload: { data: null } });
  }
}

function* watchFetchGlobalTable() {
  yield takeEvery('options/fetchGlobalTable', fetchGlobalTable);
}

export default function* rootSaga() {
  yield all([fork(watchFetchGlobalTable)]);
}

export { sendData, fetchTable, fetchDetail, deleteObject, fetchGlobalTable, fetchAuxTable };
