/* eslint-disable global-require */
// eslint-disable-next-line
import * as Sentry from "@sentry/browser";
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { isMultiColorActive, defaultColor, isDarkSwitchActive } from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';
import 'leaflet/dist/leaflet.css';

if (process.env.NODE_ENV !== 'development') {
  // Sentry.init({
  //   dsn: 'https://873a595d10684b2b94a69da56f1a2a61@sentry.backbone.sk/27'
  // });
}

const color = isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
