import _ from 'lodash';
import { rawTranslate } from '../lowLevelUtils';
import invariant from 'invariant';

const messageFromStatus = status => {
  switch (status) {
    case 404:
      return rawTranslate('response.errors.404');
    case 422:
      return rawTranslate('response.error.422');
    case 500:
      return rawTranslate('response.errors.500');
    default:
      return rawTranslate('response.error_unknown', { status });
  }
};

const extractErrorMessage = errorResponse => {
  const errorTitle = _.get(errorResponse, 'response.data.title', null);
  const exceptionMessage = _.get(errorResponse, 'message', null);
  const errorCode = _.get(errorResponse, 'code', null);
  const codeBasedError = errorCode
    ? rawTranslate(`response_errors.${errorCode.toLowerCase()}`)
    : null;
  // console.log('errorTitle', errorTitle, codeBasedError, exceptionMessage);
  return errorTitle || codeBasedError || exceptionMessage;
};

const extractStatus = response => {
  let status = _.get(response, 'response.status', null);
  if (status === null) {
    status = _.get(response, 'status', 600);
  }
  return status;
};

const extractFormErrors = validationErrors => {
  const result = {
    nonField: [],
    fields: {},
    skipped: []
  };
  // console.log('validationErrors', validationErrors);
  validationErrors.forEach(error => {
    if (error.path) {
      if (error.path[0] === '$body') {
        result.nonField.push(error.message);
      } else if (error.path[0] === 'items' && error.path.length === 1) {
        result.nonField.push(error.message);
      } else {
        const currentErrors = _.get(result.fields, error.path, []);
        currentErrors.push(error.message);
        _.set(result.fields, error.path, currentErrors);
      }
      if (error.path.length > 1) {
        result.skipped.push(error);
      }
    }
  });
  // console.log('extracted validation errors', result);

  return result;
};

const extractFormWizardErrors = (response, messages, prefix) => {
  invariant(messages, 'messages not specified');
  invariant(prefix, 'prefix not specified');
  const lines = [];
  const title = _.get(response, 'response.data.title', null);
  const validationErrors = _.get(response, 'response.data.validation_errors', null);
  if (title) {
    lines.push(title);
  }
  console.log('a', response.response);
  console.log('b', response.data);
  console.log('c', title);
  console.log('d', validationErrors);
  if (validationErrors) {
    validationErrors.forEach(e => {
      const path = e.path.join('.');
      const errorMessageKey = `${prefix}.${path}`;
      const fieldName = messages[errorMessageKey] || path;
      console.log('fn', fieldName, errorMessageKey, path, prefix);
      const line = `${fieldName}: ${e.message || e.code}`;
      lines.push(line);
    });
  }
  console.log('fwe', lines, response);
  return lines;
};

export {
  extractErrorMessage,
  messageFromStatus,
  extractFormErrors,
  extractStatus,
  extractFormWizardErrors
};
