import { getCurrentLanguage, setCurrentLanguage } from '../../helpers/Utils';
import { createSlice } from '@reduxjs/toolkit';
import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';

const initialState = {
  locale: getCurrentLanguage(),
  messages: [],
  currentTab: 0
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const locale = action.payload;
      setCurrentLanguage(locale);
      state.locale = locale;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    }
  }
});

export const createPredefinedTextsReducer = () => {
  const base = createEmptySliceBase({ name: 'predefinedTexts' });
  const withTable = injectTableSlice({
    initialSort: 'title',
    sliceBase: base
  });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export const settingsReducer = settingsSlice.reducer;
